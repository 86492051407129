import React, { forwardRef } from 'react'
import IconUpload from '../svg/IconUpload'

const AdvanceFileUpload = forwardRef(({ }, ref) => {
    return (
        <div className='h-[200px] w-full bg-light rounded-sm border border-gray-mid bg-gray-light '>
            <label htmlFor="dropzone-file" className='flex items-center justify-center flex-col gap-sm h-full' >
                <div className="icon">
                    <IconUpload className="h-8 w-8" />
                </div>
                <h5 className='text-dark text-base font-500'>Upload File</h5>
                <p className=' text-dark text-sm font-400'>Upload PDF, PNG or JPG format only</p>
            </label>
            <input
                type='file'
                id='dropzone-file'
                className='hidden'
                ref={ref}
            />
        </div>
    )
})

export default AdvanceFileUpload