import { useMemo, createContext, useState } from "react";
import { useQuery } from "react-query";
import Http from "../api/Http";
import { HOME_API_URL } from "../api/path";

const CategoryContext = createContext()

const CategoryProvider = ({ children }) => {

    const queryKey = useMemo(() => ["categoryListApi"]);
    const [categoryList, setCategoryList] = useState([])
    const [cartSidebarOpen, setCartSidebarOpen] = useState(false);

    const { data: categoryData, isLoading } = useQuery(queryKey,
        async () => {
            const response = await Http.get(HOME_API_URL.categoryList);
            setCategoryList(response?.data)
        }, {
        refetchOnWindowFocus: false,
    });

    return (
        <CategoryContext.Provider
            value={{
                categoryList,
                setCartSidebarOpen,
                cartSidebarOpen
            }}
        >
            {children}
        </CategoryContext.Provider>
    )
};

export { CategoryProvider, CategoryContext }
