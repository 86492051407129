import { useMemo, createContext, useState } from "react";
import { useQuery } from "react-query";
import Http from "../api/Http";
import { HOME_API_URL } from "../api/path";

const CmsContext = createContext()

const CmsProvider = ({ children }) => {

    const cmsKey = useMemo(() => ["cmsApi"]);
    const [cmsList, setCmsList] = useState([])

    const { data: cms, isLoadings } = useQuery(cmsKey, async () => {
        const response = await Http.get(HOME_API_URL.cmsList);
        setCmsList(response?.data);
    }, {
        refetchOnWindowFocus: false,
    });

    return (
        <CmsContext.Provider
            value={{
                cmsList,
            }}
        >
            {children}
        </CmsContext.Provider>
    )
};

export { CmsProvider, CmsContext }
