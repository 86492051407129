import { Listbox, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { buttonVariants } from '../ui/Button';
import IcnEnglishLang from '../svg/IconEnglishLang';
import { merge } from '../../lib/utils';
import IconArabicLang from '../svg/IconArabicLang';
import { useTranslation } from "react-i18next";
import useLanguage from '../../hooks/useLanguage';

const languagesData = [
    { lang: 'عربي', Icon: <IconArabicLang className="w-5 h-5" />, code: 'ar' },
    { lang: 'English', Icon: <IcnEnglishLang className="w-5 h-5" />, code: 'en' },
]

const LanguageSelect = ({ hasScrolled}) => {
    const selectRef = useRef(null);
    const overlayRef = useRef(null);
    const [selected, setSelected] = useState(languagesData[1]);
    const [open, setOpen] = useState(false);
    const { i18n } = useTranslation();
    const { setLanguage } = useLanguage()
  
    useEffect(() => {
        const storedLanguage = localStorage.getItem('i18nextLng');
        const defaultLanguage = languagesData.find((language) => language.code === storedLanguage) || languagesData[1];
        setSelected(defaultLanguage);
    }, []);

    useEffect(() => {
        if (hasScrolled) {
            setOpen(false);
        }
    }, [hasScrolled, setSelected]);

    useEffect(() => {
        document.body.dir = i18n.dir();
    }, [i18n, i18n.language]);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (open && overlayRef.current && !overlayRef.current.contains(e.target) && !selectRef.current.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [open, overlayRef, selectRef]);

    const handleSelectClick = () => {
        setOpen(!open);
    };

    const switchLanguage = (language) => {
        localStorage.setItem('i18nextLng', language.code);
        setLanguage(language.code)
        i18n.changeLanguage(language.code);
        setSelected(language);
        setOpen(false);
    };

    return (
        <div className="relative w-[140px] min-w-[140px]">
            <Listbox value={selected} onChange={switchLanguage}>
                <div className="relative w-full">
                    <Listbox.Button
                        ref={selectRef}
                        onClick={() => handleSelectClick()}
                        className={merge(buttonVariants({ variant: "outline", size: "sm", className: "w-full rounded-sm justify-between px-4 hover:bg-[unset] before:hidden hover:text-black" }))}
                    >
                        <div className="flex items-center gap-sm">
                            <span>{selected.Icon}</span>
                            <span className="block truncate">{selected.lang}</span>
                        </div>
                    </Listbox.Button>
                    {open && (
                        <div
                         onClick={()=>setOpen(false)}
                            ref={overlayRef}
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100vh', // Set to 100% instead of 100vh
                                zIndex: 99,
                            }}
                        />
                    )}
                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute w-full mt-1 bg-black border border-gray-900 rounded-xs z-[999] rounded-sm">
                            {languagesData.map((value, index) => (
                                <Listbox.Option
                                    key={index} className={({ active }) => `relative  select-none py-2 px-4 text-white text-sm cursor-pointer language-select-option`} value={value}>
                                    {({ selected }) => (
                                        <div className='flex items-center gap-sm'>
                                            <span>{value.Icon}</span>
                                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                {value.lang}
                                            </span>
                                        </div>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};

export default LanguageSelect;