import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Context from "./context/context";
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import SnackProvider from "./contexts/SnackbarProvider";
import ClientProvider from "./react-query";
import { AuthProvider } from "./contexts/AuthProvider";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <SnackProvider>
            <ClientProvider>
              <AuthProvider>
              <Context>
                <App />
                </Context>
              </AuthProvider>
            </ClientProvider>
          </SnackProvider>
        </I18nextProvider>
      </BrowserRouter>
    </HelmetProvider>
);
