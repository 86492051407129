import React from 'react'

const IconArrowRight = (props) => {
    return (
        <svg  {...props} viewBox="0 0 13 12" fill="none">
            <path d="M4.97461 2.04002L8.23461 5.30002C8.61961 5.68502 8.61961 6.31502 8.23461 6.70002L4.97461 9.96002" stroke="currentcolor" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconArrowRight