import { AUTH_API_URL } from "../api/path";
import Http from "../api/Http";
import { authStore } from "../contexts/AuthProvider";

const useLogout = () => {
    const logout_ = authStore(state => state.logout)
    const logout = async () => {
        try {
            await Http.patch(AUTH_API_URL.logout);
            logout_()

        } catch (err) {
        }
    }

    return logout;
}

export default useLogout