import React from 'react'

const IconUpload = (props) => {
    return (
        <svg {...props} viewBox="0 0 30 30" fill="none">
            <g clipPath="url(#clip0_383_1993)">
                <path d="M20.5978 18.4766C21.1947 17.8796 21.1947 16.9842 20.5978 16.3872L16.1205 11.91C15.5235 11.313 14.6281 11.313 14.0311 11.91L9.55387 16.3872C8.9569 16.9842 8.9569 17.8796 9.55387 18.4766C10.1508 19.0736 11.0463 19.0736 11.6433 18.4766L13.5834 16.5365V24.894C13.5834 25.7895 14.1804 26.3864 15.0758 26.3864C15.9713 26.3864 16.5682 25.7895 16.5682 24.894V16.5365L18.5084 18.4766C19.1053 19.0736 20.0008 19.0736 20.5978 18.4766ZM24.6273 8.77588C22.3887 3.55242 16.2698 1.01531 10.897 3.40318C7.46448 4.8956 5.07662 8.02968 4.62889 11.7607C1.49481 12.5069 -0.594574 15.7903 0.151636 18.9243C0.897845 21.4614 3.43496 23.4016 6.12131 23.4016H11.3448V21.0137C10.1508 21.3122 8.80766 20.8645 7.91221 19.969C6.41979 18.4766 6.41979 16.0887 7.91221 14.7456L12.3895 10.2683C13.1357 9.52209 14.0311 9.22361 15.0758 9.22361C16.1205 9.22361 17.016 9.67134 17.7622 10.2683L22.2394 14.7456C23.7318 16.238 23.7318 18.6258 22.2394 19.969C21.344 20.8645 20.15 21.3122 18.8069 21.0137V23.4016H22.5379C26.7167 23.4016 30 20.1183 30 15.9395C30 12.6562 27.9106 9.82058 24.6273 8.77588Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_383_1993">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconUpload