import React from 'react'

const IconGoUp = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40" fill="none">
<path d="M38.1652 28.9522C38.207 28.3569 38.0112 27.7694 37.6206 27.3183L21.8435 9.30313C21.6319 9.06247 21.3714 8.86968 21.0794 8.73761C20.7874 8.60554 20.4706 8.53723 20.1501 8.53723C19.8296 8.53723 19.5128 8.60554 19.2208 8.73761C18.9288 8.86968 18.6683 9.06247 18.4566 9.30313L2.69657 27.3183C2.30631 27.7691 2.11048 28.3562 2.15195 28.951C2.19341 29.5459 2.46879 30.1001 2.91783 30.4924C3.36759 30.8837 3.95399 31.081 4.5488 31.0411C5.14362 31.0013 5.69845 30.7275 6.09196 30.2797L20.1501 14.2048L34.2252 30.2797C34.6187 30.7275 35.1736 31.0013 35.7684 31.0411C36.3632 31.081 36.9496 30.8837 37.3994 30.4924C37.8481 30.1004 38.1235 29.5466 38.1652 28.9522Z" fill="currentcolor"/>
</svg>

  )
}

export default IconGoUp