function path(root, sublink) {
  return `${root}${sublink}`;
}
const BASE_PATH = process.env.REACT_APP_BASE_URL;
const ROOTS_PATH = BASE_PATH + "/user";
const COMMON_ROOT_PATH = BASE_PATH + "/common";

const AUTH_API_URL = {
  login: path(ROOTS_PATH, "/login"),
  registerSocial: path(ROOTS_PATH, "/register/social"),
  register: path(ROOTS_PATH, "/register"),
  otpVerify: path(ROOTS_PATH, "/otp/verify"),
  resendOtp: path(ROOTS_PATH, "/otp/resend"),
  forgetPwd: path(ROOTS_PATH, "/forgetPassword"),
  resetPwd: path(ROOTS_PATH, "/resetPassword"),
  logout: path(ROOTS_PATH, "/logout"),
  refreshToken: path(ROOTS_PATH, "/refreshToken"),
};

const HOME_API_URL = {
  latestNewsList: path(COMMON_ROOT_PATH, "/latest-news-list"),
  faqList: path(COMMON_ROOT_PATH, "/faq-list"),
  privacyPolicyGetApi: path(COMMON_ROOT_PATH, "/privacy_policy"),
  termAndConditionGetApi: path(COMMON_ROOT_PATH, "/term_condition"),
  collectionList: path(COMMON_ROOT_PATH, "/collections"),
  categoryList: path(COMMON_ROOT_PATH, "/category"),
  newsSubscription: path(COMMON_ROOT_PATH, "/news-letter/subcribe"),
  commonSettingList: path(COMMON_ROOT_PATH, "/common-setting-list"),
  cmsList: path(COMMON_ROOT_PATH, "/home"),
  newCollectionList: path(COMMON_ROOT_PATH, "/new-collection"),
  bookAppointment: path(COMMON_ROOT_PATH, "/book-appointment"),
  contactUs: path(COMMON_ROOT_PATH, "/contact-us"),
  // categoryList: path(COMMON_ROOT_PATH, "/category"),
  categoryShow: path(COMMON_ROOT_PATH, "/category/:id"),
  productDetails: path(COMMON_ROOT_PATH, "/product-details"),
  addCart: path(COMMON_ROOT_PATH, "/cart-add"),
  removeCart: path(COMMON_ROOT_PATH, "/cart-remove"),
  cartList: path(COMMON_ROOT_PATH, "/cart-list"),
  headerList: path(COMMON_ROOT_PATH, "/header-list"),
  collectionProduct: path(COMMON_ROOT_PATH, "/collection/products"),
  
  priceRange: path(COMMON_ROOT_PATH, "/products/price-range"),




}

const PRODUCT_API_URL = {
  productList: path(ROOTS_PATH, "/products"),
  productSearchList: path(ROOTS_PATH, "/products/search-product"),
  productDetails: path(ROOTS_PATH, "/product-details"),

  storeProductWishList: path(ROOTS_PATH, "/wish-list"),
  removeProductWishList: path(ROOTS_PATH, "/wish-list/remove"),
  getProductWishList: path(ROOTS_PATH, "/wish-list"),
  productsRelated: path(ROOTS_PATH, "/products-related"),
  ratingReviewList: path(ROOTS_PATH, "/rating-review-list"),
  populerList: path(ROOTS_PATH, "/populer-list"),
  

}

const IMAGE_UPLOAD_URL={
  uploadImage:path(BASE_PATH,"/upload-image")
}

const USER_API_URL = {
  changePassword: path(ROOTS_PATH, "/changePassword"),
  changeProfile: path(ROOTS_PATH, "/profile-change"),
  changeProfileImage: path(ROOTS_PATH, "/profile-image-change"),
  ticketList:path(ROOTS_PATH,"/contact-support"),
  ticketCreate:path(ROOTS_PATH,"/contact-support"),
  ticketReply:path(ROOTS_PATH,"/ticket-reply"),

  addressList: path(ROOTS_PATH, "/addresses"),
  addressCreate: path(ROOTS_PATH, "/address"),
  addressUpdate: path(ROOTS_PATH, "/address/:_id"),
  addressDefault: path(ROOTS_PATH, "/address/default"),
  zipCodeList: path(ROOTS_PATH, "/address/zipcodes"),
  storeReview: path(ROOTS_PATH, "/rating-review"),
  countryList: path(ROOTS_PATH, "/countries"),
  mainAreaList: path(ROOTS_PATH, "/main-areas"),
  subAreaList: path(ROOTS_PATH, "/sub-areas"),
}

const CART_API_URL={
    addCart: path(ROOTS_PATH, "/cart-add"),
    removeCart: path(ROOTS_PATH, "/cart-remove"),
    cartList: path(ROOTS_PATH, "/cart-list"),
    checkout: path(ROOTS_PATH, "/checkout"),
    createCard: path(ROOTS_PATH, "/verifyCard"),
    retriveCard: path(ROOTS_PATH, "/getVerifyCardDetails"),
    verifyDetails: path(ROOTS_PATH, "/verifyDetails"),
    cardList: path(ROOTS_PATH, "/getCardDetails"),
    destroyCard: path(ROOTS_PATH, "/destroyCard"),
    createCharge: path(ROOTS_PATH, "/createCharge"),
  }

const ORDER_HISTORY_URL={
    getOrderHstory: path(ROOTS_PATH, "/orderHistory"),
    orderProduct: path(ROOTS_PATH, "/order/product"),
    orderProductTrack: path(ROOTS_PATH, "/order/status"),
    orderGenerateInvoice: path(ROOTS_PATH, "/order/generate-invoice"),
   
  }
  const REVIEW_API_URL={
    list: path(ROOTS_PATH, "/rating-reviews"),
    delete: path(ROOTS_PATH, "/rating-review-delete"),
  }


export { AUTH_API_URL, HOME_API_URL ,USER_API_URL,IMAGE_UPLOAD_URL,PRODUCT_API_URL,CART_API_URL,ORDER_HISTORY_URL,REVIEW_API_URL};
