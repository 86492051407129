import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import IconClose from "../svg/IconClose";
import { Button } from "../ui/Button";
import Input from "../ui/Input";
import * as Yup from "yup";
import { USER_API_URL } from "../../api/path";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { useMutation, useQueryClient, useQuery } from "react-query";
import Http from "../../api/Http";
import SelectBox from "../ui/SelectBox";
import { withTranslation } from "react-i18next";

const AddAddressModal = ({
  t,
  addAddressModalOpen,
  setAddAddressModalOpen,
  handleClose,
  editData,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const countries = useMemo(() => ["countryListApi"]);
  const mainArea = useMemo(() => ["mainAreaListApi"]);

  const [countryData, setCountryData] = useState([]);
  const [mainAreaData, setMainArea] = useState([]);
  const [subAreaData, setSubArea] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedMainArea, setSelectedMainArea] = useState(null);
  const [selectedSubArea, setSelectedSubArea] = useState(null);

  const { data: countryList } = useQuery(
    countries,
    async () => {
      const response = await Http.get(USER_API_URL.countryList);
      if (response) {
        setCountryData(response?.data);
      }
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: mainAreaList } = useQuery(
    mainArea,
    async () => {
      const response = await Http.get(USER_API_URL.mainAreaList);
      if (response) {
        setMainArea(response?.data);
      }
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutateAsync: subAreaList } = useMutation(
    async (id) => {
      try {
        let data = { main_area_id: id };
        const response = await Http.post(USER_API_URL.subAreaList, data);

        if (response) {
          setSubArea(response?.data);
        }
      } catch (err) { }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const addressSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        t("validation_message.first_name_matches")
      )
      .max(25, t("validation_message.first_name_min_max"))
      .required(t("validation_message.first_name_req")),
    lastName: Yup.string()
      .trim()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        t("validation_message.last_name_matches")
      )
      .max(25, t("validation_message.last_name_min_max"))
      .required(t("validation_message.last_name_req")),
    address: Yup.string()
      .trim()
      .required(t("validation_message.address"))
      .matches(
        /^[a-zA-Z0-9\s.,'-]*$/,
        "Invalid characters. Please use only letters, numbers, spaces, and some special characters."
      ),
    streetAddress: Yup.string()
      .trim()
      // .required(t("validation_message.street_address"))
      .matches(
        /^[a-zA-Z0-9\s.,'-]*$/,
        "Invalid characters. Please use only letters, numbers, spaces, and some special characters."
      ),
    country: Yup.string().required(t("validation_message.country_name")),
    is_primary: Yup.number(),
    city: Yup.string()
      .trim()
      .when("is_primary", {
        is: 0,
        then: () =>
          Yup.string()
            .trim()
            .required(t("validation_message.city_name"))
            .matches(
              /^[a-zA-Z\s]+$/,
              "City name should only contain letters and spaces."
            ),
      }),
    state: Yup.string()
      .trim()
      .when("is_primary", {
        is: 0,
        then: () =>
          Yup.string()
            .trim()
            .required(t("validation_message.state_name"))
            .matches(
              /^[a-zA-Z\s]+$/,
              "State name should only contain letters and spaces."
            ),
      }),
    postalCode: Yup.string()
      .trim()
      .when("is_primary", {
        is: 0,
        then: () =>
          Yup.string()
            .trim()
            .required(t("validation_message.postal_code"))
            .matches(
              /^\d{6}(-\d{4})?$/,
              "Invalid Postal code format. Use either 123456 or 123456-7890."
            ),
      }),
    mainArea: Yup.string()
      .nullable()
      .when("is_primary", {
        is: 1,
        then: () =>
          Yup.string().required(t("validation_message.main_area_name")),
      }),
    subArea: Yup.string()
      .nullable()
      .when("is_primary", {
        is: 1,
        then: () =>
          Yup.string().required(t("validation_message.sub_area_name")),
      }),
    phoneNumber: Yup.string()
      .trim()
      .matches(/^[0-9]+$/, t("validation_message.phone_matches"))
      .min(8, t("validation_message.phone_max"))
      .max(15, t("validation_message.phone_max"))
      .required(t("validation_message.phone_req")),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: editData?.firstName ?? "",
      lastName: editData?.lastName ?? "",
      address: editData?.address ?? "",
      streetAddress: editData?.streetAddress ?? "",
      country: editData?.country ?? null,
      mainArea: editData?.mainArea ?? null,
      subArea: editData?.subArea ?? null,
      city: editData?.city ?? "",
      state: editData?.state ?? "",
      postalCode: editData?.postalCode ?? "",
      is_primary: editData?.city !== "" ? 0 : 1,
      phoneNumber: editData?.phoneNumber ?? "",
    },
    validationSchema: addressSchema,
    onSubmit: async (values) => {
      addressRequest(values);
    },
  });

  const { mutateAsync: addressRequest } = useMutation(async (data) => {
    try {
      let response = "";
      if (!editData) {
        response = await Http.post(USER_API_URL.addressCreate, data);
      } else {
        response = await Http.patch(
          USER_API_URL.addressUpdate.replace(":_id", editData._id),
          data
        );
      }
      if (response) {
        formik.resetForm();
        setAddAddressModalOpen(false);

        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });

        queryClient.invalidateQueries(["addressApi"]);
        refetch();
      }
    } catch (err) {
      const error = err?.response;

      if (error) {
        if (error.data?.errors) {
          const errorData = error.data?.errors;
          Object.values(errorData).forEach((value) => {
            enqueueSnackbar(value, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        } else {
          enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    }
  });

  const { handleSubmit, getFieldProps, touched, errors, values, resetForm } =
    formik;

  const handleCountryChange = (selectedValue) => {
    formik.setFieldValue("country", selectedValue);
  };

  const handleMainAreaChange = async (selectedValue) => {
    formik.setFieldValue("mainArea", selectedValue);
    formik.setFieldValue("subArea", null);
    await subAreaList(selectedValue);
    setSelectedSubArea(null);
  };

  const handleSubAreaChange = (selectedValue) => {
    formik.setFieldValue("subArea", selectedValue);
  };

  const handleIsPrimaryChange = (isPrimary) => {
    formik.setFieldValue("is_primary", isPrimary);
  };

  const handleModelClose = () => {
    handleClose(resetForm());
    setSelectedSubArea(null);
    setSelectedMainArea(null);
    setSelectedCountry(null);
  };

  const fetchSubAreaData = async (id) => {
    await subAreaList(id);
  };

  useEffect(() => {
    if (editData && editData?.mainArea) {
      fetchSubAreaData(editData?.mainArea);
    }
  }, [editData]);

  return (
    <Transition appear show={addAddressModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => handleModelClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-key00"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-sm bg-white shadow-xl transition-all">
                <div className="dialog-content">
                  <div className="dialog-header flex items-center justify-center px-6 py-4 bg-gray-light ">
                    <h5 className="text-base-20 font-500 text-center text-dark capitalize">
                      {editData
                        ? t("button_label.edit_address")
                        : t("button_label.add_new_address")}
                    </h5>
                    <span
                      className="close cursor-pointer absolute top-4 end-6"
                      onClick={() => handleModelClose()}
                    >
                      <IconClose className="w-5 sm:w-6 h-5 sm:h-6 text-gray-dark " />
                    </span>
                  </div>
                  <div className="dialog-body p-6">
                    <form onSubmit={handleSubmit} value={formik}>
                      <div className="field-part mb-6">
                        <div className="form-item mb-4">
                          <Input
                            placeholder={t(
                              "input_filed_placeholder.first_name"
                            )}
                            {...getFieldProps("firstName")}
                            error={touched.firstName && errors.firstName}
                            helpertext={touched.firstName && errors.firstName}
                          />
                        </div>
                        <div className="form-item mb-4">
                          <Input
                            placeholder={t("input_filed_placeholder.last_name")}
                            {...getFieldProps("lastName")}
                            error={touched.lastName && errors.lastName}
                            helpertext={touched.lastName && errors.lastName}
                          />
                        </div>
                        <div className="form-item mb-4">
                          <Input
                            placeholder={t("input_filed_placeholder.address")}
                            {...getFieldProps("address")}
                            error={touched.address && errors.address}
                            helpertext={touched.address && errors.address}
                          />
                        </div>
                        <div className="form-item mb-4">
                          <Input
                            placeholder={t(
                              "input_filed_placeholder.street_address"
                            )}
                            {...getFieldProps("streetAddress")}
                            error={
                              touched.streetAddress && errors.streetAddress
                            }
                            helpertext={
                              touched.streetAddress && errors.streetAddress
                            }
                          />
                        </div>
                        <div className="form-item mb-4">
                          <SelectBox
                            value={values.country}
                            onChange={handleCountryChange}
                            searchbar={true}
                            data={countryData}
                            listButtonTextClass={"text-sm"}
                            buttonClassName={"ps-4"}
                            listItemClassName={"ps-4"}
                            placeholder={t(
                              "input_filed_placeholder.select_country"
                            )}
                            onIsPrimaryChange={handleIsPrimaryChange}
                            selected={selectedCountry}
                            setSelected={setSelectedCountry}
                          />
                          {touched.country && errors.country !== undefined ? (
                            <p class="text-sm text-red-500 text-start">
                              {touched.country && errors.country}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {values.is_primary === 1 ? (
                          <>
                            <div className="form-item mb-4">
                              <SelectBox
                                value={values.mainArea}
                                onChange={handleMainAreaChange}
                                searchbar={true}
                                data={mainAreaData}
                                listButtonTextClass={"text-sm"}
                                buttonClassName={"ps-4"}
                                listItemClassName={"ps-4"}
                                placeholder={t(
                                  "input_filed_placeholder.select_main_area"
                                )}
                                selected={selectedMainArea}
                                setSelected={setSelectedMainArea}
                              />
                              {touched.mainArea &&
                                errors.mainArea !== undefined ? (
                                <p class="text-sm text-red-500 text-start">
                                  {touched.mainArea && errors.mainArea}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="form-item mb-4">
                              <SelectBox
                                value={values.subArea}
                                onChange={handleSubAreaChange}
                                searchbar={true}
                                data={subAreaData}
                                listButtonTextClass={"text-sm"}
                                buttonClassName={"ps-4"}
                                listItemClassName={"ps-4"}
                                placeholder={t(
                                  "input_filed_placeholder.select_sub_area"
                                )}
                                selected={selectedSubArea}
                                setSelected={setSelectedSubArea}
                              />
                              {touched.subArea &&
                                errors.subArea !== undefined ? (
                                <p class="text-sm text-red-500 text-start">
                                  {touched.subArea && errors.subArea}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="form-item mb-4">
                              <Input
                                placeholder={t("input_filed_placeholder.city")}
                                {...getFieldProps("city")}
                                error={touched.city && errors.city}
                                helpertext={touched.city && errors.city}
                              />
                            </div>
                            <div className="form-item mb-4">
                              <Input
                                placeholder={t("input_filed_placeholder.state")}
                                {...getFieldProps("state")}
                                error={touched.state && errors.state}
                                helpertext={touched.state && errors.state}
                              />
                            </div>
                            <div className="form-item mb-4">
                              <Input
                                placeholder={t(
                                  "input_filed_placeholder.postal_code"
                                )}
                                {...getFieldProps("postalCode")}
                                error={touched.postalCode && errors.postalCode}
                                helpertext={
                                  touched.postalCode && errors.postalCode
                                }
                              />
                            </div>
                          </>
                        )}
                        <div className="form-item mb-4">
                          <Input
                            placeholder={t(
                              "input_filed_placeholder.enter_phone_number"
                            )}
                            {...getFieldProps("phoneNumber")}
                            error={touched.phoneNumber && errors.phoneNumber}
                            helpertext={
                              touched.phoneNumber && errors.phoneNumber
                            }
                          />
                        </div>
                      </div>
                      <div className="action-part text-center">
                        <Button
                          type="submit"
                          className={"sm:max-w-[80%] w-full mx-auto"}
                        >
                          {t("button.save_address")}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default withTranslation()(AddAddressModal);
