import React from 'react'

const IconRemove = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 20" fill="none">
            <path d="M5 10H15" stroke="currentcolor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconRemove