import React from 'react'

const IconArrow = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" >
            <g clipPath="url(#clip0_1105_6455)">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.7382 34.3781C13.0873 33.7272 13.0873 32.6719 13.7382 32.021L24.6048 21.1544C25.2373 20.5219 25.2373 19.4772 24.6048 18.8447L13.7382 7.97805C13.0873 7.32718 13.0873 6.27191 13.7382 5.62103C14.389 4.97016 15.4443 4.97016 16.0952 5.62103L26.9618 16.4877C28.8961 18.4219 28.8961 21.5772 26.9618 23.5114L16.0952 34.3781C15.4443 35.0289 14.389 35.0289 13.7382 34.3781Z" fill="currentcolor" />
            </g>
            <defs>
                <clipPath id="clip0_1105_6455">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconArrow