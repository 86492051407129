import React from 'react'

const IconClose = (props) => {
    return (
        <svg {...props} viewBox="0 0 32 32" fill="none">
            <path d="M12.227 19.7734L19.7737 12.2267M19.7737 19.7734L12.227 12.2267M16.0003 29.3334C23.3337 29.3334 29.3337 23.3334 29.3337 16C29.3337 8.66669 23.3337 2.66669 16.0003 2.66669C8.66699 2.66669 2.66699 8.66669 2.66699 16C2.66699 23.3334 8.66699 29.3334 16.0003 29.3334Z" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconClose