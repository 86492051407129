import React from 'react'
import files from '../../helpers/helper';
import { withTranslation } from 'react-i18next';
import useLanguage from '../../hooks/useLanguage';
import {Helmet } from "react-helmet-async";


const LatestNews = ({ news, t }) => {
    const { language } = useLanguage()
    return (
        <>
        <Helmet>
            <title>Discover the Latest Trends and Must-Have Products: Shop Smart with Our Online Shopping Blog!</title>
            <meta name="description" content="Discover the latest trends, tips, and insights in online shopping on our blog. Explore expert advice, product reviews, and more to enhance your shopping experience." />
        </Helmet>
        <section className='popular-collection-section section-gap' id='latest-news'>
            <div className="container">
                <div className="section-title mb-10 md:mb-[60px]">
                    <div data-aos="fade-right"
                        data-aos-duration="800" className="fancy-title flex  items-center justify-center gap-2 sm:gap-4">
                        <h3 className='text-base-18 font-600 rtl:font-500 sm:text-lg-24 md:text-lg-28 font-cormorant uppercase fancy-heading-shape leading-1'>{t('home_module.latest_news')}</h3>
                    </div>
                </div>
                <div className="popular-collection-section-content">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-6">
                        {
                            news?.slice(0, 4).map((values, key) => (
                                <div className="blog-card flex group items-center gap-4 lg:gap-6 lg:block" key={key}>
                                    <div className="img-wrapper lg:mb-4 h-[80px] w-[120px] md:h-[120px]  md:w-[120px] flex-shrink-0 lg:w-full lg:h-[220px] xl:h-[280px] overflow-hidden">
                                        <img src={files(values.coverImage, 'latest-news')} alt="" className='object-fit object-center w-full h-full group-hover:scale-105 transition-all duration-300' />
                                    </div>
                                    <h6 className='text-base sm:text-base-18 group-hover: text-dark font-400 line-clamp-2'>
                                        {language == 'ar' ? values.ar_description : values.description}
                                    </h6>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default withTranslation()(LatestNews)