import React, { useEffect, useState } from 'react'
import IconRemove from '../svg/IconRemove'
import IconAdd from '../svg/IconAdd'
import authStores from "../../contexts/AuthProvider";
import Http from '../../api/Http';
import { CART_API_URL, HOME_API_URL } from '../../api/path';
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

const QuntityHandler = ({ count, productId, setQuntityCount, setId, rateId }) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [totalCount, setTotalCount] = useState();

    const handleDecrement = (productId) => {
        removeToCart(productId)
    }
    const handleIncrement = (productId) => {
        addToCart(productId)
    }
    const { sessionId, isAuthenticated } = authStores((state) => ({
        sessionId: state.sessionId,
        isAuthenticated: state.isAuthenticated
    }));

    const { mutateAsync: addToCart } = useMutation(
        async (data) => {
            return await isAuthenticated ? Http.post(CART_API_URL.addCart, { product_id: data, rate_id: rateId }) : Http.post(HOME_API_URL.addCart, { sessionId: sessionId, product_id: data, rate_id: rateId });
        },
        {
            onSuccess: async ({ data }) => {
                queryClient.invalidateQueries(["cartListApi"]);
            },
            onError: (error) => {
                const errorMessage = error?.response?.data?.message;
                if (errorMessage) {
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            },
        }
    );

    const { mutateAsync: removeToCart } = useMutation(
        async (data) => {
            return await isAuthenticated ? Http.post(CART_API_URL.removeCart, { product_id: data, rate_id: rateId }) : Http.post(HOME_API_URL.removeCart, { sessionId: sessionId, product_id: data, rate_id: rateId });
        },
        {
            onSuccess: async ({ data }) => {
                setTotalCount(data?.cartLength)
                queryClient.invalidateQueries(["cartListApi"]);
                queryClient.invalidateQueries(["productDetailsApi"]);
            },
            onError: (error) => {
                const errorMessage = error?.response?.data?.message;
                if (errorMessage) {
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            },
        }
    );

    useEffect(() => {
        const currentUrl = window.location.pathname;
        if (totalCount == 0) {
            if (currentUrl == '/checkout') {
                window.history.back();
            }
        }
    }, [totalCount]);

    useEffect(() => {
        setQuntityCount(count)
        setId(productId)
    }, [count]);

    return (
        <div className='quntity-handler inline-flex bg-gray-light'>
            <button className='w-8 h-8 flex items-center justify-center border-none outline-none group/handlersub hover:bg-dark' onClick={() => handleDecrement(productId)}>
                <IconRemove className="w-5 h-5  text-gray-dark group-hover/handlersub:!text-white" />
            </button>
            <input type="button" value={count} className="w-8 h-8 text-sm font-600 text-dark" />
            <button className='w-8 h-8 flex items-center justify-center border-none outline-none group/handleradd hover:bg-dark' onClick={() => handleIncrement(productId)}>
                <IconAdd className="w-5 h-5 text-gray-dark group-hover/handleradd:!text-white" />
            </button>
        </div>
    )
}

export default QuntityHandler