import React from 'react'

const IconUpload2 = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40" fill="none">
    <path d="M24.9999 37.9168H14.9999C5.94992 37.9168 2.08325 34.0502 2.08325 25.0002V15.0002C2.08325 5.95016 5.94992 2.0835 14.9999 2.0835H24.9999C34.0499 2.0835 37.9166 5.95016 37.9166 15.0002V25.0002C37.9166 34.0502 34.0499 37.9168 24.9999 37.9168ZM14.9999 4.5835C7.31659 4.5835 4.58325 7.31683 4.58325 15.0002V25.0002C4.58325 32.6835 7.31659 35.4168 14.9999 35.4168H24.9999C32.6833 35.4168 35.4166 32.6835 35.4166 25.0002V15.0002C35.4166 7.31683 32.6833 4.5835 24.9999 4.5835H14.9999Z" fill="currentcolor"/>
    <path d="M15.0001 17.9167C12.4667 17.9167 10.4167 15.8667 10.4167 13.3333C10.4167 10.8 12.4667 8.75 15.0001 8.75C17.5334 8.75 19.5834 10.8 19.5834 13.3333C19.5834 15.8667 17.5334 17.9167 15.0001 17.9167ZM15.0001 11.25C14.4475 11.25 13.9176 11.4695 13.5269 11.8602C13.1362 12.2509 12.9167 12.7808 12.9167 13.3333C12.9167 13.8859 13.1362 14.4158 13.5269 14.8065C13.9176 15.1972 14.4475 15.4167 15.0001 15.4167C15.5526 15.4167 16.0825 15.1972 16.4732 14.8065C16.8639 14.4158 17.0834 13.8859 17.0834 13.3333C17.0834 12.7808 16.8639 12.2509 16.4732 11.8602C16.0825 11.4695 15.5526 11.25 15.0001 11.25ZM4.44841 32.8317C4.18055 32.8321 3.91971 32.746 3.70473 32.5862C3.48974 32.4264 3.33212 32.2014 3.25532 31.9448C3.17852 31.6882 3.18665 31.4136 3.27851 31.162C3.37036 30.9104 3.54102 30.6951 3.76508 30.5483L11.9817 25.0317C13.7817 23.815 16.2651 23.965 17.8984 25.3483L18.4484 25.8317C19.2817 26.5483 20.6984 26.5483 21.5151 25.8317L28.4484 19.8817C30.2151 18.365 32.9984 18.365 34.7817 19.8817L37.4984 22.215C38.0151 22.665 38.0817 23.4483 37.6317 23.9817C37.1817 24.4983 36.3984 24.565 35.8651 24.115L33.1484 21.7817C32.3151 21.065 30.8984 21.065 30.0651 21.7817L23.1317 27.7317C21.3651 29.2483 18.5817 29.2483 16.7984 27.7317L16.2484 27.2483C15.4817 26.5983 14.2151 26.5317 13.3651 27.115L5.14841 32.6317C4.93175 32.765 4.68175 32.8317 4.44841 32.8317Z" fill="currentcolor"/>
    </svg>
  )
}

export default IconUpload2