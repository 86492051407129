import React, { useEffect, useState } from 'react'
import FrontHeader from './FrontHeader'
import { Outlet, useLocation } from 'react-router-dom'
import FrontFooter from './FrontFooter'
import CartSidebar from '../../components/front/CartSidebar'
import BackDrop from '../../components/common/BackDrop'
import useCategory from '../../hooks/useCategory'
import { Button } from '../../components/ui/Button'
import IconGoUp from '../../components/svg/IconGoUp'
import AOS from 'aos';
import 'aos/dist/aos.css';

const FrontMaster = () => {
    const [toogleSidebar, setToogleSidebar] = useState(false);
    const [searchToogleActive, setSearchToogleActive] = useState(false)
    const { setCartSidebarOpen, cartSidebarOpen } = useCategory();
    const [hasScrolled, sethasScrolled] = useState()
    const [showGo, setshowGo] = useState(false);
    const { pathname } = useLocation();

    const handleGoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        const handleScroll = () => {
            let scrollTrigger = 50;
            let scrollFromTop = window.scrollY
            if (scrollFromTop > scrollTrigger) {
                setshowGo(true)
                sethasScrolled(true)
            } else {
                setshowGo(false)
                sethasScrolled(false)
            }
        }
        window.addEventListener("scroll", handleScroll);
    }, [])

    useEffect(() => {
        setTimeout(() => {
            AOS.init()

        }, 500);
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [cartItemCount, setCartItemCount] = useState();

    useEffect(() => {
        if (searchToogleActive || cartSidebarOpen || toogleSidebar) {
            document.documentElement.style.overflowY = "hidden"
        } else {
            document.documentElement.style.overflowY = "auto"
        }
    }, [cartSidebarOpen, searchToogleActive, toogleSidebar])

    return (
        <>
            {
                searchToogleActive || cartSidebarOpen ? <BackDrop searchToogleActive={searchToogleActive} setSearchToogleActive={setSearchToogleActive} toogleSidebar={toogleSidebar} setToogleSidebar={setToogleSidebar} setCartSidebarOpen={setCartSidebarOpen} /> : null
            }
            <Button onClick={handleGoTop} className={`p-0 before:hidden group bg-white hover:bg-black  border border-gray-dark shadow-2xl  w-[48px] h-[48px] transition-all duration-500 ease-in-out ${showGo ? "opacity-100 visible" : "opacity-0 invisible"}  fixed bottom-4 right-4 sm:bottom-5 sm:right-5 z-[99]  rounded-full flex items-center justify-center`}>
                <IconGoUp className="w-5 h-5 text-gray-dark group-hover:text-white transition-all duration-300" />
            </Button>
            <FrontHeader cartItemCount={cartItemCount} hasScrolled={hasScrolled} searchToogleActive={searchToogleActive} setSearchToogleActive={setSearchToogleActive} toogleSidebar={toogleSidebar} setToogleSidebar={setToogleSidebar} setCartSidebarOpen={setCartSidebarOpen} cartSidebarOpen={cartSidebarOpen} />
            <main className={`mt-[83px] sm:mt-[78px] min-h-[500px] ${hasScrolled ? "lg:mt-[148px]" : "lg:mt-[137px]"} `}>
                {/* {cartSidebarOpen && <CartSidebar setCartItemCount={setCartItemCount} setCartSidebarOpen={setCartSidebarOpen} cartSidebarOpen={cartSidebarOpen} />} */}
                <CartSidebar setCartItemCount={setCartItemCount} setCartSidebarOpen={setCartSidebarOpen} cartSidebarOpen={cartSidebarOpen} />
                <div className="main-wrapper">
                    <Outlet />
                </div>
            </main>
            <FrontFooter />
        </>
    )
}

export default FrontMaster