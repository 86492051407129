import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom'
import { HOME_API_URL } from '../../api/path';
import { useQuery } from 'react-query';
import Http from '../../api/Http';
import useLanguage from '../../hooks/useLanguage';
import { withTranslation } from 'react-i18next';

const PrivacyPolicy = ({ t }) => {
    const scroll = useRef(null);
    const [enPolicy, setEnPolicy] = useState();
    const [arPolicy, setArPolicy] = useState();
    const { language } = useLanguage()

    const srollToTop = () => {
        scroll.current.scrollIntoView({ block: 'end', behavior: 'instant' });
    }
    useEffect(() => {
        srollToTop()
    }, [])



    const queryKey = useMemo(() => ["commonSettingListApi"]);
    const { data: commonSettingList, isLoading } = useQuery(
        queryKey,
        async () => {
            const response = await Http.get(HOME_API_URL.privacyPolicyGetApi);
            setEnPolicy(response?.data[0]?.value)
            setArPolicy(response?.data[1]?.value)
            return response?.data;
        }, {
        refetchOnWindowFocus: false,
    });

    return (
        <>
            <div id={'scroll'} ref={scroll} />
            <Helmet>
                <title>Privacy Policy - Mailarti</title>
                <meta name="description" content="Mailarti Privacy Policy. Please read the Policy carefully. We recognize the importance of protecting the privacy of your personal and financial information." />
            </Helmet>
            <section className='section-gap legal-docs !pt-8'>
                <div className="container">
                    <div className="">
                        <div className="section-content">
                            <div className="title-part">
                                <h3 className='text-2xl sm:text-3xl lg:text-4xl font-600 font-cormorant rtl:!font-500  text-dark text-center mb-2'>{t('front_footer.privacy_policy')}</h3>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <div dangerouslySetInnerHTML=
                                    {{ __html: language == 'ar' ? arPolicy : enPolicy }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="">
                        <div className="section-title text-center mb-14">
                            <div className="mb-3">
                                <h3 className='text-2xl text-black font-600'>Privacy Policy</h3>
                                <h3 className='text-gray-dark text-base'><span className='text-dark'>Effective Date:</span> January 01, 2024</h3>
                            </div>
                            <p className='!text-base text-black font-400'>Mailarti is committed to protecting your privacy and the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and store your personal information when you visit our website, make a purchase, or use our services.</p>
                        </div>
                        <div className="section-content">
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Information We Collect:</h5>
                                <p className='!text-base text-dark mb-2'>We collect personal information in several ways, including:</p>
                                <div className="">
                                    <ul>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'><span>Information you provide directly:</span> This includes your name, email address, shipping address, payment information, and any other information you choose to share with us, such as your birthdate, preferences, or feedback.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'><span>Information about your browsing activity:</span> We may collect information about your browsing activity on our website, such as the pages you visit, the products you view, and the links you click. This information is collected through cookies and similar technologies.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>Information from third-party sources: We may receive information about you from third-party sources, such as social media platforms or marketing partners. This information may include your name, email address, and demographic information</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>How We Use Your Information:</h5>
                                <p className='!text-base text-dark mb-2'>We use your personal information for the following purposes:</p>
                                <div className="">
                                    <ul>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>To fulfill your orders and deliver your purchases.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>To send you important information about your orders, such as order confirmations and shipping updates.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>To respond to your inquiries and provide customer support.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>To send you marketing communications, such as newsletters and promotional offers, with your consent.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>To personalize your experience on our website.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>To improve our website and services.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>To prevent fraud and protect our customers.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Sharing Your Information:</h5>
                                <p className='!text-base text-gray-dark mb-2'>We will not share your personal information with third-party companies for their own marketing purposes without your consent. We may share your information with third-party service providers who help us fulfill your orders, provide customer support, or run our website. These service providers are obligated to keep your information confidential and secure.</p>
                                <p className='!text-base text-gray-dark mb-2'>We may also be required to disclose your personal information if required by law, in response to a legal process, or to protect the safety of others.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Cookies and Similar Technologies:</h5>
                                <p className='!text-base text-gray-dark mb-2'>We use cookies and similar technologies to track your browsing activity on our website. We use this information to personalize your experience, improve our website, and provide you with relevant advertising. You can control your cookie preferences through your web browser settings.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Your Data Rights:</h5>
                                <p className='!text-base text-dark mb-2'> You have the right to access, correct, and delete your personal information. You also have the right to opt out of receiving marketing communications from us. You can exercise these rights by contacting us at [insert your email address].</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Data Security:</h5>
                                <p className='!text-base text-gray-dark mb-2'>We use cookies and similar technologies to track your browsing activity on our website. We use this information to personalize your experience, improve our website, and provide you with relevant advertising. You can control your cookie preferences through your web browser settings.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Children's Privacy:</h5>
                                <p className='!text-base text-gray-dark mb-2'>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Changes to this Privacy Policy:</h5>
                                <p className='!text-base text-gray-dark mb-2'>We may update this Privacy Policy from time to time. We will post any changes on our website. We encourage you to review this Privacy Policy periodically to stay informed about how we protect your personal information.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Contact Us:</h5>
                                <p className='!text-base text-gray-dark mb-2'>If you have any questions about this Privacy Policy, please contact us at <Link to="" className="underline"> support@mailarti.com.</Link></p>
                                <p className='!text-base text-gray-dark mb-2'>This Privacy Policy is effective as of January 01, 2024.</p>
                            </div>
                        </div>
                    </div> */}

            </section >
        </>
    )
}

export default withTranslation()(PrivacyPolicy)