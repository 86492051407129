import React from 'react'

const IconPhone = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M36.6167 30.55C36.6167 31.15 36.4834 31.7667 36.2 32.3667C35.9167 32.9667 35.55 33.5333 35.0667 34.0667C34.25 34.9667 33.35 35.6167 32.3334 36.0333C31.3334 36.45 30.25 36.6667 29.0834 36.6667C27.3834 36.6667 25.5667 36.2667 23.65 35.45C21.7334 34.6333 19.8167 33.5333 17.9167 32.15C15.9802 30.7335 14.1529 29.1736 12.45 27.4833C10.7647 25.7867 9.21021 23.9648 7.80004 22.0333C6.43337 20.1333 5.33337 18.2333 4.53337 16.35C3.73337 14.45 3.33337 12.6333 3.33337 10.9C3.33337 9.76667 3.53337 8.68333 3.93337 7.68333C4.33337 6.66667 4.96671 5.73333 5.85004 4.9C6.91671 3.85 8.08337 3.33333 9.31671 3.33333C9.78337 3.33333 10.25 3.43333 10.6667 3.63333C11.1 3.83333 11.4834 4.13333 11.7834 4.56667L15.65 10.0167C15.95 10.4333 16.1667 10.8167 16.3167 11.1833C16.4667 11.5333 16.55 11.8833 16.55 12.2C16.55 12.6 16.4334 13 16.2 13.3833C15.9834 13.7667 15.6667 14.1667 15.2667 14.5667L14 15.8833C13.8167 16.0667 13.7334 16.2833 13.7334 16.55C13.7334 16.6833 13.75 16.8 13.7834 16.9333C13.8334 17.0667 13.8834 17.1667 13.9167 17.2667C14.2167 17.8167 14.7334 18.5333 15.4667 19.4C16.2167 20.2667 17.0167 21.15 17.8834 22.0333C18.7834 22.9167 19.65 23.7333 20.5334 24.4833C21.4 25.2167 22.1167 25.7167 22.6834 26.0167C22.7667 26.05 22.8667 26.1 22.9834 26.15C23.1167 26.2 23.25 26.2167 23.4 26.2167C23.6834 26.2167 23.9 26.1167 24.0834 25.9333L25.35 24.6833C25.7667 24.2667 26.1667 23.95 26.55 23.75C26.9334 23.5167 27.3167 23.4 27.7334 23.4C28.05 23.4 28.3834 23.4667 28.75 23.6167C29.1167 23.7667 29.5 23.9833 29.9167 24.2667L35.4334 28.1833C35.8667 28.4833 36.1667 28.8333 36.35 29.25C36.5167 29.6667 36.6167 30.0833 36.6167 30.55Z" stroke="currentcolor" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
    )
}

export default IconPhone