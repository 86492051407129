import React from 'react'

const IconProfile = (props) => {
    return (
        <svg {...props} viewBox="0 0 32 32" fill="none" >
            <path d="M27.4535 29.3333C27.4535 24.1733 22.3202 20 16.0002 20C9.68021 20 4.54688 24.1733 4.54688 29.3333M16.0002 16C17.7683 16 19.464 15.2976 20.7143 14.0474C21.9645 12.7971 22.6669 11.1014 22.6669 9.33334C22.6669 7.56523 21.9645 5.86954 20.7143 4.61929C19.464 3.36905 17.7683 2.66667 16.0002 2.66667C14.2321 2.66667 12.5364 3.36905 11.2862 4.61929C10.0359 5.86954 9.33354 7.56523 9.33354 9.33334C9.33354 11.1014 10.0359 12.7971 11.2862 14.0474C12.5364 15.2976 14.2321 16 16.0002 16Z" stroke="currentcolor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconProfile