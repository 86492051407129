import clsx from "clsx"
import { twMerge } from "tailwind-merge"
import IconArrow from "../components/svg/IconArrow";

export const merge = (...input) => {
    return twMerge(clsx(input))
}

export function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <button style={{border:"1px solid #101010"}} className={className + " w-8 h-8 rounded-full flex items-center transition-all duration-300 justify-center top-[40%] group/nav !left-[unset] hover:bg-black hover:!border-transparent -translate-y-1/2 !end-0 xxl:!-end-10 before:hidden z-10"} onClick={onClick}><IconArrow className="text-dark w-4 h-4 transition-all duration-300 group-hover/nav:text-white"/></button>
    );
}

export function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button style={{border:"1px solid #101010"}} className={className + " w-8 h-8 rounded-full flex items-center transition-all duration-300 justify-center top-[40%] group/nav hover:bg-black  !right-[unset] hover:!border-transparent -translate-y-1/2 !start-0 xxl:!-start-10 before:hidden z-10"} onClick={onClick}><IconArrow className="text-dark w-4 h-4 rotate-180 transition-all duration-300 group-hover/nav:text-white"/></button>
    );
}

export function SimpleSampleNextArrow(props) {
    const { className, onClick } = props;
return (
        <button className={className + " w-8 h-8 rounded-full flex items-center transition-all duration-300 justify-center top-[50%] group/nav !left-[unset] -translate-y-1/2 !end-0 xxl:!-end-10 before:hidden z-10"} onClick={onClick}><IconArrow className="text-dark w-6 h-6"/></button>
    );
}

export function SimpleSamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button className={className + " w-8 h-8 rounded-full flex items-center transition-all duration-300 justify-center top-[50%] group/nav !right-[unset] -translate-y-1/2 !start-0 xxl:!-start-10 before:hidden z-10"} onClick={onClick}><IconArrow className="text-dark w-6 h-6 rotate-180"/></button>
    );
}
