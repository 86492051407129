import React from 'react'

const IconArabicLang = (props) => {
    return (
        <svg {...props  } viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_18_2955)">
        <path d="M0 24.5161V29.8064C0 32 1.74194 33.5484 3.87097 33.5484H36.129C38.2581 33.5484 40 32 40 29.8064V24.5161H0Z" fill="#DB2727"/>
        <path d="M36.129 6.45166H3.87097C1.74194 6.45166 0 8.00005 0 10.1936V15.4839H40V10.1936C40 8.00005 38.2581 6.45166 36.129 6.45166Z" fill="#359846"/>
        <path d="M0 15.4839H40V24.5161H0V15.4839Z" fill="white"/>
        <path d="M36.0001 33.5484C38.1291 33.5484 40.0001 32 40.0001 29.8064V24.5161H25.4194L36.0001 33.5484Z" fill="#C61A1A"/>
        <path d="M36.1292 6.45166H3.87109L14.5808 15.4839H40.0001V10.1936C40.0001 8.00005 38.2582 6.45166 36.1292 6.45166Z" fill="#218930"/>
        <path d="M25.4839 24.5161H40V15.4839H14.5161L25.4839 24.5161Z" fill="#CFE5E2"/>
        <path d="M36.1292 6.45166H3.87109L33.7421 15.4839H40.0001V10.1936C40.0001 8.00005 38.2582 6.45166 36.1292 6.45166Z" fill="#167221"/>
        <path d="M40.0001 17.2903V15.4839H33.4194L40.0001 17.2903Z" fill="#BAD3CF"/>
        <path d="M40 29.742C40 32.1291 38.2581 33.5485 36.129 33.5485H3.87097C1.74194 33.5485 0 31.871 0 29.6775" fill="#B20B0B"/>
        <path d="M0 10.1935V29.871C0 30.4516 0.129032 31.0322 0.387097 31.5484L7.09677 24.7742V15.2258L0.387097 8.51611C0.129032 9.03224 0 9.61289 0 10.1935Z" fill="#383737"/>
        <path d="M0 15.4839V15.4194V29.871C0 30.4517 0.129032 31.0323 0.387097 31.5485L7.09677 24.7743V15.4839" fill="#151515"/>
        </g>
        <defs>
        <clipPath id="clip0_18_2955">
        <rect width="40" height="40" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}

export default IconArabicLang