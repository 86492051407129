import PropTypes from "prop-types";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { PATH_PRODUCT } from "../routes/path";

// ----------------------------------------------------------------------

ProductDetailsGuard.propTypes = {
  children: PropTypes.node,
};

export default function ProductDetailsGuard({ children }) {
  const { state } = useLocation();
  const { productId } = useParams();
 
  if (!productId) {
    return <Navigate to={PATH_PRODUCT.searchResults} />;
  }

  return <>{children}</>;
}
