import { useMemo, createContext, useState } from "react";
import { useQuery } from "react-query";
import Http from "../api/Http";
import { HOME_API_URL } from "../api/path";

const CollectionContext = createContext()

const CollectionProvider = ({ children }) => {

    const queryKey = useMemo(() => ["collectionListApi"]);
    const [collectionList, setCollectionList] = useState([])

    const { data: collectionData, isLoading } = useQuery(queryKey,
        async () => {
            const response = await Http.get(HOME_API_URL.collectionList);
            setCollectionList(response?.data)
        }, {
        refetchOnWindowFocus: false,
    });

    return (
        <CollectionContext.Provider
            value={{
                collectionList,
            }}
        >
            {children}
        </CollectionContext.Provider>
    )
};

export { CollectionProvider, CollectionContext }
