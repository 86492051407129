import React from 'react'

const IconRadioUnchecked = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <circle cx="20" cy="20" r="19.5" stroke="#76777B" />
        </svg>
    )
}

export default IconRadioUnchecked