import React, { useState, useMemo, useEffect } from 'react'
import IconClose from '../svg/IconClose'
import QuntityHandler from './QuntityHandler'
import { Button } from '../ui/Button'
import { CART_API_URL, HOME_API_URL } from '../../api/path'
import { useMutation, useQuery, useQueryClient } from "react-query";
import Http from '../../api/Http'
import files from '../../helpers/helper'
import authStores from "../../contexts/AuthProvider";
import { useNavigate } from 'react-router-dom'
import { PATH_AUTH, PATH_PRODUCT } from '../../routes/path'
import IconDelete from '../svg/IconDelete'
import { enqueueSnackbar } from 'notistack'
import { withTranslation } from 'react-i18next'
import useLanguage from '../../hooks/useLanguage'
import CircularProgress from '@mui/material/CircularProgress';
import base64 from 'base-64'

const CartSidebar = ({ cartSidebarOpen, setCartSidebarOpen, t, setCartItemCount }) => {
    const [cartList, setCartList] = useState([]);
    const [total, setTotal] = useState(0);
    const [deletedItem, setDeletedItem] = useState(null);
    const queryKey = useMemo(() => ["cartListApi"]);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { sessionId, isAuthenticated } = authStores((state) => ({
        sessionId: state.sessionId,
        isAuthenticated: state.isAuthenticated
    }));
    const [quntityCount, setQuntityCount] = useState(0);
    const [id, setId] = useState(0);

    const { language } = useLanguage()
    const [totalCount, setTotalCount] = useState();

    useEffect(() => {
        setCartItemCount(cartList?.length);
    }, [cartList]);

    const { data: cartData, isLoading } = useQuery(queryKey,
        async () => {
            const response = isAuthenticated ? await Http.post(CART_API_URL.cartList) : await Http.post(HOME_API_URL.cartList, { sessionId: sessionId.toString() });
            setCartList(response?.data?.products)
            setTotal(response?.data?.overallTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }))
        }, {
        refetchOnWindowFocus: false,
    });

    const handleOnCheckout = () => {
        if (!isAuthenticated) {
            setCartSidebarOpen(false)
            navigate(PATH_AUTH.login)
        } else {
            setCartSidebarOpen(false)
            navigate(PATH_PRODUCT.checkout, { state: { total: total, quntityCount: quntityCount, id: id } })
        }
    }

    const { mutateAsync: removeToCart, isLoading: isRemove } = useMutation(
        async (data) => {
            return await isAuthenticated ? Http.post(CART_API_URL.removeCart, { ...data, type: 'delete_all' }) : Http.post(HOME_API_URL.removeCart, { ...data, sessionId: sessionId, type: 'delete_all' });
        },
        {
            onSuccess: async ({ data }) => {
                setTotalCount(data?.cartLength)

                queryClient.invalidateQueries(["cartListApi"]);
                queryClient.invalidateQueries(["productDetailsApi"]);
                queryClient.invalidateQueries(["getWishListAPI"]);

                enqueueSnackbar(t('validation_message.product_remove'), {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });

            },
            onError: (error) => {
                const errorMessage = error?.response?.data?.message;
                if (errorMessage) {
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            },
        }
    );

    useEffect(() => {
        const currentUrl = window.location.pathname;
        if (totalCount == 0) {
            if (currentUrl == '/checkout') {
                window.history.back();
            }
        }
    }, [totalCount]);

    const handleDivClick = (productId) => {
        setCartSidebarOpen(false)
        navigate(PATH_PRODUCT.productDetailNew(base64.encode(productId)), { state: { productId: productId } });
    };


    return (
        <>
            {
                <div className={`sidebar fixed top-0 z-50  ${cartSidebarOpen ? "end-0" : "-end-[100%]"} w-full sm:w-[480px] h-full bg-gray-light`}>
                    <div className="sidebar-header flex items-center justify-between p-4 bg-black rtl:flex-row-reverse">
                        <h5 className='font-cormorant text-base-24 font-500 text-white'>{t("comman_text.shopping_cart")}</h5>
                        <button onClick={() => setCartSidebarOpen(false)}>
                            <IconClose className="w-6 h-6 text-white" />
                        </button>
                    </div>
                    <div className="sidebar-body p-4 h-[calc(100%-139px)] overflow-y-auto theme-scrollbar">
                        {
                            cartList.length ?
                                <div className="card-items-list">
                                    <ul>
                                        {cartList?.map((value, index) => (
                                            <li key={index} className='mb-2 last:mb-0'>
                                                <div className={`card-item p-4 flex items-start sm:items-center gap-md border border-black-light  last:border-b-gold-light`}>
                                                    <div className="img-part bg-gray-light  w-[80px] h-[80px] sm:w-[120px] sm:h-[120px] flex items-center justify-center flex-shrink-0 overflow-hidden rounded-sm cursor-pointer" onClick={() => handleDivClick(value.product_id)}>
                                                        <img src={files(value.cover_image, 'thumbnail')} alt="" />
                                                    </div>
                                                    <div className="flex items-start gap-2 flex-grow">
                                                        <div className="text-content flex-grow">
                                                            <h6 className='font-nunito !text-base text-dark mb-2 font-300 cursor-pointer' onClick={() => handleDivClick(value.product_id)}>{language == 'ar' ? value.ar_name : value.name} </h6>
                                                            <h5 className='!font-cardillac text-base text-dark font-500 mb-2'>{value.total_price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 })}</h5>
                                                            {value.size && <p className="text-sm font-400 text-dark mb-2">{t("comman_text.size")} : {value.size}</p>}
                                                            {value.color && <p className="text-sm font-400 text-dark mb-2">{t("comman_text.color")} : {t(`color.${value.color}`)}</p>}
                                                            <QuntityHandler count={value.quantity} setQuntityCount={setQuntityCount} setId={setId} productId={value.product_id} rateId={value.rateId} />
                                                        </div>

                                                        {isRemove && deletedItem === value._id ? <CircularProgress /> :
                                                            <button title="Delete" onClick={() => { removeToCart({ product_id: value.product_id, rate_id: value.rateId }); setDeletedItem(value._id) }}>
                                                                <IconDelete className="w-5 h-5 hover:text-black text-gray-700" />
                                                            </button>
                                                        }

                                                    </div>
                                                </div>
                                            </li>)
                                        )}
                                    </ul>
                                </div>
                                :
                                <div className='text-base-18 font-500 text-dark card-item h-full w-full flex items-center justify-center'>
                                    {t("comman_text.cart_empty")}
                                </div>}
                    </div>
                    {
                        cartList.length ?
                            <div className="font-nunito sidebar-action p-4">
                                <Button className={"w-full"} onClick={() => handleOnCheckout()}>
                                    {t("comman_text.checkout")} ({total})
                                </Button>
                            </div> : ""
                    }

                </div>
            }
        </>
    )
}

export default withTranslation()(CartSidebar)