import axios from "axios";
import Security from "./Security";
import { refreshStore } from "../contexts/AuthProvider";
import { AUTH_API_URL } from "./path";
import useLogout from "../hooks/useLogout";

function AxiosMiddleware(method, url, data, options) {
  if (data.env !== "test" && url.search("env=test") === -1 && options && options?.Accept !== "multipart/form-data") {
    data = new Security().encrypt(data);
  }
  const authStorage = JSON.parse(localStorage.getItem("auth-storage"));
  // const isAuthenticated = authStorage.state.isAuthenticated;
  // if (isAuthenticated) {
  axios.defaults.headers.common['X-localization'] = `${localStorage.getItem('i18nextLng')}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${authStorage.state.accessToken}`;
  
  axios.defaults.headers.common["lang"] = `${localStorage.getItem('i18nextLng')}`;

  // }
  axios.defaults.headers.common["site"] = `mailarti`;
  axios.defaults.withCredentials = true;
  switch (method) {
    case "get":
      return axios.get(url, data, options);
    case "post":
      return axios.post(url, data, options);
    case "head":
      return axios.head(url, data, options);
    case "patch":
      return axios.patch(url, data, options);
    case "put":
      return axios.put(url, data, options);
    case "delete":
      return axios.delete(url, { data: data, headers: options });
    default:
      break;
  }
}
// let token = document.head.querySelector('meta[name="csrf-token"]');
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
axios.interceptors.response.use(
  (response) => {
    if (response.data.mac !== undefined) {
      response.data = new Security().decrypt(response.data);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 423) {
      console.log("Your account has been blocked");
      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: AUTH_API_URL.logout,
      };
      axios.request(config).then((response) => {
        localStorage.clear();
        window.location.reload();
      })
        .catch((error) => {
          localStorage.clear();
          window.location.reload();
        });
    }
    
    if (error.response.status === 401) {
      const authStorage = JSON.parse(localStorage.getItem("auth-storage"));
      const refreshToken = authStorage.state.refreshToken;

      if (refreshToken && !refreshStore.getState().isRefreshing) {
        refreshStore.setState({ isRefreshing: true });
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: AUTH_API_URL.refreshToken,
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        };
        axios.request(config).then((response) => {
          refreshStore.setState({ isRefreshing: false });
          if (response.data.accessToken) {
            authStorage.state.accessToken = response.data.accessToken;
            localStorage.setItem("auth-storage", JSON.stringify(authStorage));
          }
          if (response.data.refreshToken) {
            authStorage.state.refreshToken = response.data.refreshToken;
            localStorage.setItem("auth-storage", JSON.stringify(authStorage));
          }
        })
          .catch((error) => {
            localStorage.clear();
            window.location.reload();
          });
      }
    }
    if (error.response.status === 409) {
    }
    if (error.response.status === 422) {
    }
    return Promise.reject(error);
  }
);

export function get(url, data = [], options = {}) {
  return AxiosMiddleware("get", url, data, options);
}
export function post(url, data = [], options = {}) {
  return AxiosMiddleware("post", url, data, options);
}
export function head(url, data = [], options = {}) {
  return AxiosMiddleware("head", url, data, options);
}
export function patch(url, data = [], options = {}) {
  return AxiosMiddleware("patch", url, data, options);
}
export function put(url, data = [], options = {}) {
  return AxiosMiddleware("put", url, data, options);
}
export function del(url, data = [], options = {}) {
  return AxiosMiddleware("delete", url, data, options);
}
export function setBearerToken(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
export function setVerifyToken(token) {
  axios.defaults.headers.common["VerifyToken"] = `${token}`;
}
