import React from 'react';

const IconDown = (props) => {
    return (
        <svg {...props} viewBox="0 0 17 17" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.43159 6.47065C2.69194 6.2103 3.11405 6.2103 3.3744 6.47065L7.72107 10.8173C7.97405 11.0703 8.39194 11.0703 8.64492 10.8173L12.9916 6.47065C13.2519 6.2103 13.674 6.2103 13.9344 6.47065C14.1947 6.731 14.1947 7.15311 13.9344 7.41346L9.58773 11.7601C8.81405 12.5338 7.55194 12.5338 6.77826 11.7601L2.43159 7.41346C2.17124 7.15311 2.17124 6.731 2.43159 6.47065Z" fill="currentColor" />
        </svg>
    );
}

export default IconDown;
