import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom'
import { HOME_API_URL } from '../../api/path';
import Http from '../../api/Http';
import { useQuery } from 'react-query';
import DataLoader from '../../components/common/DataLoader';
import useLanguage from '../../hooks/useLanguage';
import { withTranslation } from 'react-i18next';

const TermsAndConditon = ({ t }) => {
    const scroll = useRef(null);
    const { language } = useLanguage()
    const [enTermCondition, setEnTermCondition] = useState();
    const [arTermCondition, setArTermCondition] = useState();

    const srollToTop = () => {
        scroll.current.scrollIntoView({ block: 'end', behavior: 'instant' });
    }
    useEffect(() => {
        srollToTop()
    }, [])

    const queryKey = useMemo(() => ["commonSettingListApi"]);
    const { data: commonSettingList, isLoading } = useQuery(
        queryKey,
        async () => {
            const response = await Http.get(HOME_API_URL.termAndConditionGetApi);
            setEnTermCondition(response?.data[0]?.value)
            setArTermCondition(response?.data[1]?.value)
            return response?.data;
        }, {
        refetchOnWindowFocus: false,
    });

    return (
        <div className='relative'>
            <div id={'scroll'} ref={scroll} />
            <Helmet>
                <title>Terms of Use  - Mailarti</title>
                <meta name="description" content="Check out Mailarti Consumer terms and conditions Please read these terms and conditions carefully before placing your order." />
            </Helmet>
            {isLoading ? <DataLoader /> :
                <section className='section-gap legal-docs !pt-8'>
                    <div className="container">
                        <div className="">
                            <div className="section-content">
                                <div className="title-part">
                                    <h3 className='text-2xl sm:text-3xl lg:text-4xl font-600 font-cormorant rtl:!font-500  text-dark text-center mb-2'>{t('front_footer.terms_condition')}</h3>
                                </div>
                                <div className="content-part mb-6 last:mb-0">
                                    <div dangerouslySetInnerHTML=
                                        {{ __html: language == 'ar' ? arTermCondition : enTermCondition }}>
                                    </div>
                                </div></div>
                        </div></div>
                    {/* <div className='container'>
                    <div className="">
                        <div className="section-title text-center mb-14">
                            <div className="mb-3">
                                <h3 className='text-xl md:text-2xl text-black font-600'> Terms & Conditions</h3>
                                <h3 className='text-gray-dark text-base'><span className='text-dark'>Effective Date:</span> January 01, 2024</h3>
                            </div>
                            <p className='!text-base text-black font-400'>Welcome to Mailarti! These Terms & Conditions ("Terms") govern your use of our website, ("Website"), services, and products ("Services"), provided by Mailarti ("us", "we", or "our"). Please read these Terms carefully before using our Services. By accessing or using our Services, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not use our Services.</p>
                        </div>
                        <div className="section-content">
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Use of Services:</h5>
                                <div className="">
                                    <ul>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You must be at least 18 years old to use our Services.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You are responsible for all activity that occurs under your account and agree to comply with all applicable laws and regulations.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You may not use our Services for any unlawful purpose or in violation of these Terms.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You may not modify, reverse engineer, disassemble, or decompile any part of our Services.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You may not transmit any viruses, worms, or other harmful content through our Services.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Purchases:</h5>
                                <div className="">
                                    <ul>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>All purchases made through our Services are final and non-refundable, unless otherwise stated.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>We reserve the right to refuse any order for any reason.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You are responsible for all taxes and fees associated with your purchases.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>We accept no responsibility for lost or damaged items during shipping.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'> Content</h5>
                                <div className="">
                                    <ul>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>Our Services may contain content that is owned by us or by third parties. This content is protected by copyright and other intellectual property laws.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You may not use any content from our Services without our express permission.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You may not submit any content that is unlawful, harmful, or offensive.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>We reserve the right to remove any content from our Services at any time, for any reason.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'> User Accounts:</h5>
                                <div className="">
                                    <ul>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You may create an account ("Account") to use certain features of our Services.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You are responsible for maintaining the confidentiality of your Account information and password.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You are solely responsible for all activity that occurs under your Account.</li>
                                        <li className='text-base text-gray-dark mb-2 relative ps-4 before:absolute before:w-[5px] before:h-[5px] before:bg-black before:rounded-full before:start-0  before:top-2.5'>You may not transfer your Account to anyone else.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Disclaimer:</h5>
                                <p className='!text-base text-dark mb-2'> Our Services are provided "as is" and without warranty of any kind, express or implied. We disclaim all warranties, including but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that our Services will be uninterrupted, secure, or error-free. You use our Services at your own risk.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Limitation of Liability:</h5>
                                <p className='!text-base text-gray-dark mb-2'>To the fullest extent permitted by law, we will not be liable for any damages arising out of your use of our Services, including but not limited to, direct, indirect, incidental, consequential, and punitive damages.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Indemnification:</h5>
                                <p className='!text-base text-gray-dark mb-2'>You agree to indemnify and hold us harmless from and against any and all claims, losses, damages, liabilities, costs, and expenses (including attorneys' fees) arising out of your use of our Services or your violation of these Terms.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Termination:</h5>
                                <p className='!text-base text-gray-dark mb-2'>We may terminate your access to our Services at any time, for any reason, without notice. We may also remove any content from our Services at any time, for any reason.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Governing Law and Dispute Resolution:</h5>
                                <p className='!text-base text-gray-dark mb-2'>These Terms shall be governed by and construed in accordance with the laws of the State of Kuwait, without regard to its conflict of laws provisions. Any dispute arising out of or relating to these Terms shall be resolved by binding arbitration in accordance with the rules of Kuwait. The arbitration shall be held in Kuwait. The award of the arbitrator shall be final and binding on the parties.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Entire Agreement:</h5>
                                <p className='!text-base text-gray-dark mb-2'>These Terms constitute the entire agreement between you and us regarding your use of our Services.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Changes to Terms:</h5>
                                <p className='!text-base text-gray-dark mb-2'>We may update these Terms from time to time. We will post any changes on our Website. We encourage you to review these Terms periodically to stay informed about our updates.</p>
                            </div>
                            <div className="content-part mb-6 last:mb-0">
                                <h5 className='!text-base-20 text-black font-600 mb-2'>Contact Us:</h5>
                                <p className='!text-base text-gray-dark mb-2'>If you have any questions about these Terms, please contact us at <Link to="" className="underline"> support@mailarti.com.</Link></p>
                                <p className='!text-base text-gray-dark mb-2'>Thank you for using Mailarti!</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                </section >
            }
        </div>
    )
}

export default withTranslation()(TermsAndConditon)