import React from 'react'

const BackDrop = ({setCartSidebarOpen,cartSidebarOpen,setToogleSidebar,toogleSidebar,setSearchToogleActive}) => {
  const handleClick =()=>{
    setCartSidebarOpen(false)
    setToogleSidebar(false)
    setSearchToogleActive(false)
  }

  return (
    <div className='h-screen w-full top-0 left-0 fixed bg-[#000000]/40 z-40' onClick={handleClick}>
        
    </div>
  )
}

export default BackDrop