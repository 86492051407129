import React from 'react'

const IconClose2 = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <g clipPath="url(#clip0_1106_6467)">
                <path d="M8 32L32 8M32 32L8 8" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1106_6467">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconClose2