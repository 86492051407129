import React from 'react'

const IconSend = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path d="M5.43978 12.0001H10.8398M9.50978 4.23013L18.0698 8.51013C21.9098 10.4301 21.9098 13.5701 18.0698 15.4901L9.50978 19.7701C3.74978 22.6501 1.39978 20.2901 4.27978 14.5401L5.14978 12.8101C5.36978 12.3701 5.36978 11.6401 5.14978 11.2001L4.27978 9.46013C1.39978 3.71013 3.75978 1.35013 9.50978 4.23013Z" stroke="currentcolor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconSend