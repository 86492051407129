function path(root, sublink) {
    return `${root}${sublink}`;
}
const ROOTS_AUTH = "/";

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    otpVerification: path(ROOTS_AUTH, "otp-verification"),
    login: path(ROOTS_AUTH, "login"),
    changePassword: path(ROOTS_AUTH, "change-password"),
};

export const PATH_PROFILE = {
    profile: path(ROOTS_AUTH, "profile"),
    ourStores: path(ROOTS_AUTH, "our-stores"),
    ourWishList: path(ROOTS_AUTH, "wishlist"),
    addTicket: path(ROOTS_AUTH, "profile/add-ticket"),
    contactSupport: path(ROOTS_AUTH, "profile/contact-support"),
};

export const PATH_HOME = {
    root: ROOTS_AUTH,
};

export const PATH_PRODUCT = {
    productDetail: path(ROOTS_AUTH,"product-detail"),
    productDetailNew: (productId) => path(ROOTS_AUTH, `product-detail/${productId}`), // Dynamic route
    searchResults: path(ROOTS_AUTH,"search-results"),
    checkout: path(ROOTS_AUTH,"checkout"),
    ordertrack: path(ROOTS_AUTH,"ordertrack"),
};