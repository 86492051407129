import * as Yup from "yup";

// export const registerValidationSchema = Yup.object().shape({
//     first_name: Yup.string()
//         .trim()
//         .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "First Name can only contain alphabet letters")
//         .max(25, "First name should be a maximum of 25 characters!")
//         .required("First name is required"),
//     last_name: Yup.string()
//         .trim()
//         .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "Last name can only contain alphabet letters")
//         .max(25, "Last name should be a maximum of 25 characters!")
//         .required("Last name is required"),
//     email: Yup.string()
//         .trim()
//         .email("Must be a valid email address")
//         .required("Email address is required"),
//     phone: Yup.string()
//         .matches(/^[0-9]+$/, 'Phone number should contain only numeric digits.')
//         .min(8, "Phone number can be between 8 and 15 characters!")
//         .max(15, "Phone number can be between 8 and 15 characters!")
//         .required("Phone number is required"),
//     password: Yup.string()
//         .trim()
//         .min(8, "Password length should be at least 8 character long and must contain both upper and lower case letters along with at least one number and one special character.(!,@,#,$,%,^,&,*)")
//         .max(50, "Password length should be at least 8 character long and must contain both upper and lower case letters along with at least one number and one special character.(!,@,#,$,%,^,&,*)")
//         .matches(/[A-Z]+/, "Password length should be at least 8 character long and must contain both upper and lower case letters along with at least one number and one special character.(!,@,#,$,%,^,&,*)")
//         .matches(/[a-z]+/, "Password length should be at least 8 character long and must contain both upper and lower case letters along with at least one number and one special character.(!,@,#,$,%,^,&,*)")
//         .matches(/[0-9]+/, "Password length should be at least 8 character long and must contain both upper and lower case letters along with at least one number and one special character.(!,@,#,$,%,^,&,*)")
//         .matches(/(?=.*?[#?!@$%^&*-])/, "Password length should be at least 8 character long and must contain both upper and lower case letters along with at least one number and one special character.(!,@,#,$,%,^,&,*)")
//         .required("Password is required"),
//     confirm_password: Yup.string()
//         .oneOf([Yup.ref("password"), null], "Confirm password must be same as new password")
//         .required("Confirm password is required"),
// });
export const registerValidationSchema = (t) => {
    return Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, t('validation_message.first_name_matches'))
            .max(25, t('validation_message.first_name_min_max'))
            .required(t("validation_message.first_name_req")),
        last_name: Yup.string()
            .trim()
            .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, t("validation_message.last_name_matches"))
            .max(25, t("validation_message.last_name_min_max"))
            .required(t("validation_message.last_name_req")),
        email: Yup.string()
            .trim()
            .email(t("validation_message.valid_email"))
            .required(t("validation_message.email_req")),
        phone: Yup.string()
            .matches(/^[0-9]+$/, t("validation_message.phone_matches"))
            .min(8, t("validation_message.phone_max"))
            .max(15, t("validation_message.phone_max"))
            .required(t("validation_message.phone_req")),
        password: Yup.string()
            .trim()
            .min(8, t("validation_message.password_length"))
            .max(50, t("validation_message.password_length"))
            .matches(/[A-Z]+/, t("validation_message.password_length"))
            .matches(/[a-z]+/, t("validation_message.password_length"))
            .matches(/[0-9]+/, t("validation_message.password_length"))
            .matches(/(?=.*?[#?!@$%^&*-])/, t("validation_message.password_length"))
            .required(t("validation_message.password_req")),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], t("validation_message.password_match"))
            .required(t("validation_message.confirm_password_req")),
    });
}
export const loginValidationSchema = (t) => {
    // email: Yup.string()
    //     .trim()
    //     .email("Must be a valid email address")
    //     .required("Email address is required"),
    // password: Yup.string()
    //     .trim()
    //     .required("Password is required"),
    return Yup.object().shape({
        email: Yup.string()
          .trim()
          .email(t('validation_message.valid_email'))
          .required(t('validation_message.email_req')),
        password: Yup.string()
          .trim()
          .required(t('validation_message.password_req')),
      });
};
export const forgetPwdValidationSchema = (t) => {
  return Yup.object().shape({
    email: Yup.string()
        .trim()
        .email(t('validation_message.valid_email'))
        .required(t('validation_message.email_req')),
    }); 
}
export const changePwdValidationSchema = (t) => { 
    return Yup.object().shape({
        current_password: Yup.string()
            .trim()
            .min(6, t('validation_message.current_password_min_max'))
            .max(50, t('validation_message.current_password_min_max'))
            .required(t('validation_message.current_password_req')),
        new_password: Yup.string()
            .trim()
            .min(8, t('validation_message.password_length'))
            .max(50, t('validation_message.password_length'))
            .matches(/[A-Z]+/, t('validation_message.password_length'))
            .matches(/[a-z]+/, t('validation_message.password_length'))
            .matches(/[0-9]+/, t('validation_message.password_length'))
            .matches(/(?=.*?[#?!@$%^&*-])/, t('validation_message.password_length'))
            .required(t('validation_message.new_password_req')),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("new_password"), null], t('validation_message.password_match'))
            .required(t('validation_message.confirm_password_req')),
    }); 
}

export const forgetPassValidationSchema = (t) => {
   return Yup.object().shape({
    new_password: Yup.string()
        .trim()
        .min(8, t('validation_message.password_length'))
        .max(50, t('validation_message.password_length'))
        .matches(/[A-Z]+/, t('validation_message.password_length'))
        .matches(/[a-z]+/, t('validation_message.password_length'))
        .matches(/[0-9]+/, t('validation_message.password_length'))
        .matches(/(?=.*?[#?!@$%^&*-])/, t('validation_message.password_length'))
        .required(t('validation_message.new_password_req')),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], t('validation_message.password_match'))
        .required(t('validation_message.confirm_password_req')),
    }); 
}

export const profileValidationSchema = (t) => {
    return Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,t('validation_message.first_name_matches'))
            .max(25, t('validation_message.first_name_min_max'))
            .required(t('validation_message.first_name_req')),
        last_name: Yup.string()
            .trim()
            .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, t('validation_message.last_name_matches'))
            .max(25, t('validation_message.last_name_min_max'))
            .required(t('validation_message.last_name_req')),
        phone: Yup.string()
            .matches(/^[0-9]+$/, t('validation_message.phone_matches'))
            .min(8, t('validation_message.phone_max'))
            .max(15, t('validation_message.phone_max'))
            .required(t('validation_message.phone_req')),
    }); 
}
export const appointmentValidationSchema = (t) => {
    return Yup.object().shape({
    name: Yup.string()
        .trim()
        .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, t('validation_message.name_match'))
        .max(25, "Name should be a maximum of 25 characters!")
        .required(t('validation_message.name_req')),
    email: Yup.string()
        .trim()
        .email(t('validation_message.valid_email'))
        .required(t('validation_message.email_req')),
    phone: Yup.string()
        .min(8, t('validation_message.phone_max'))
        .max(15, t('validation_message.phone_max'))
        .required(t('validation_message.phone_req')),
    date: Yup.date()
        .required(t('validation_message.appointment_date_req')),
    reason: Yup.string()
        .max(500,t('validation_message.character_limit')),
    }); 
}

export const contactUsValidationSchema = (t) => {
    return Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, t('validation_message.name_match'))
            .max(25, "Name should be a maximum of 25 characters!")
            .required(t('validation_message.name_req')),
        email: Yup.string()
            .trim()
            .email(t('validation_message.valid_email'))
            .required(t('validation_message.email_req')),
        phone: Yup.string()
            .min(8, t('validation_message.phone_max'))
            .max(15, t('validation_message.phone_max')),
        reason: Yup.string()
            .max(500, t('validation_message.character_limit'))
            .required(t('validation_message.message_req')),
    });
}

export const ticketSupport = (t) => {
    return Yup.object().shape({
        title: Yup.string()
            .trim()
            .required(t('validation_message.title_req')),
        message: Yup.string()
            .trim()
            .required(t('validation_message.message_req')),
        }); 
}

export const ticketReply = (t) => {
   return Yup.object().shape({
    message: Yup.string()
        .trim()
        .required(t('validation_message.message_req'))
        .max(500, t('validation_message.character_limit', { max: 500 }))
    });
}

export const reviewRating = Yup.object().shape({
    star: Yup.number().min(0, 'Rating must be 0 or greater').required('Rating is required'),
    comment: Yup.string()
        .trim()
        .required("Message is required"),
});