import React, { cloneElement, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconArrow from "../../components/svg/IconArrow";
import IconCart from "../../components/svg/IconCart";
import IconClose2 from "../../components/svg/IconClose2";
import IconHeart from "../../components/svg/IconHeart";
import IconMenu from "../../components/svg/IconMenu";
import IconProfile from "../../components/svg/IconProfile";
import Logo from "../../components/svg/Logo";
import { HeaderMegaMenuNavigation } from "../../data/static";
import { PATH_AUTH, PATH_PRODUCT, PATH_PROFILE } from "../../routes/path";
import IconSearch2 from "../../components/svg/IconSearch2";
import IconPhone from "../../components/svg/IconPhone";
import authStores from "../../contexts/AuthProvider";
import { useMutation, useQuery } from 'react-query'
import Http from "../../api/Http";
import { HOME_API_URL, PRODUCT_API_URL } from "../../api/path";
import JewelleryMegaMenu from "../../components/front/JewelleryMegaMenu";
import files from "../../helpers/helper";
import useLogout from "../../hooks/useLogout";
import LanguageSelect from "../../components/common/LanguageSelect";
import { withTranslation } from 'react-i18next';
import useLanguage from "../../hooks/useLanguage";
import { Button } from "../../components/ui/Button";
import base64 from 'base-64'

const FrontHeader = ({
  setCartSidebarOpen,
  cartSidebarOpen,
  setToogleSidebar,
  toogleSidebar,
  setSearchToogleActive,
  searchToogleActive,
  hasScrolled,
  t,
  cartItemCount
}) => {
  const [widthSize, setWidthSize] = useState(0);
  const [activeNavLink, setActiveNavLink] = useState(null);
  const [aractiveNavLink, setarActiveNavLink] = useState(null);
  const [headerMegaMenuNavigation, setHeaderMegaMenuNavigation] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = authStores((state) => state.user);
  const isAuthenticated = authStores((state) => state.isAuthenticated);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState("");
  const [searchText, setSearchText] = useState("");
  const [productList, setProductList] = useState([]);

  const [authState, authStateSet] = useState();

  const { language } = useLanguage()

  const handleNavClick = (actionLink, ar_name) => {
    setarActiveNavLink(ar_name)
    setActiveNavLink(actionLink);
  };

  const logout = useLogout();

  const handleLogout = () => {
    logout()
    setToogleSidebar(false);
    setTimeout(() => {
      setActiveNavLink(null);
      setarActiveNavLink(null)
    }, 500);
    navigate(PATH_AUTH.login);
  }

  const handleToogleSidebar = () => {
    if (cartSidebarOpen) {
      setCartSidebarOpen(false);
    } else {
      setToogleSidebar(!toogleSidebar);
    }
  };

  const handleToogleCartSidebar = () => {
    if (toogleSidebar) {
      setToogleSidebar(false);
    } else {
      setCartSidebarOpen(!cartSidebarOpen);
    }
  };

  const handleMenuClose = () => {
    setToogleSidebar(false);
    setTimeout(() => {
      setActiveNavLink(null);
      setarActiveNavLink(null)
    }, 500);
  };

  let resizeWindow = () => {
    setWidthSize(window.innerWidth);
  };

  useEffect(() => {
    handleMenuClose();
    setSearchToogleActive(false);
  }, [pathname]);

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if (user) {
      setEmail(user.email ? user.email : "");
      setFirstName(user.first_name ? user.first_name : "");
      setLastName(user.last_name ? user.last_name : "");
      setProfile(user.profile ? user.profile : "");
    }
  }, [user]);

  //   useEffect(()=>{
  //     if(widthSize > 1){
  //         setSearchToogleActive(false)
  //     }
  //   },[widthSize])

  const handleSearch = async (e) => {
    const value = e.target.value.trim();
    if (e.which === 13 || e.key === "Enter" || e.keyCode === 13) {
      setSearchToogleActive(false);

      const response = await Http.post(PRODUCT_API_URL.productSearchList, { search: value });    // to get category and price range data as per search      
      setSearchText('');
      setProductList([])
      navigate(`/search-results`, {
        state: { searchText: value, categoryListData: response?.data ?? [] },
      });
    }
  }

  const handleViewAll = async (e) => {
    const response = await Http.post(PRODUCT_API_URL.productSearchList, { search: searchText });    // to get category and price range data as per search      
    setSearchText('');
    setProductList([])
    navigate(`/search-results`, {
      state: { searchText: searchText, categoryListData: response?.data ?? [] },
    });
    setSearchToogleActive(false)
  }

  const handleSuggestionSearch = async (e) => {
    const value = e.target.value.trim();
    setSearchText(value);
    if (value) {
      getProductData(value)
    } else {
      setProductList([])
    }
  }

  const { mutateAsync: getProductData } = useMutation(
    async (data) => {
      return await Http.post(PRODUCT_API_URL.productList, { page: 0, search: data, limit: 6, attribute_ids: [], sort: 1, category_id: [] });
    },
    {
      onSuccess: async ({ data }) => {
        setProductList(data?.ProductData?.data)
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // blank search on refresh
  // useEffect(() => {
  //   navigate(`/search-results`, {
  //     state: { searchText: null },
  //   });  
  // }, []);

  let mobileClass = `fixed top-0 ${toogleSidebar ? "start-0" : "-start-full"
    } z-[999] bg-white h-screen w-full sm:w-[325px] transition-start duration-500 ease-in-out p-0 overflow-hidden`;

  const headerList = useMemo(() => ["headerListApi"]);

  const { data: headerListData, isLoading } = useQuery(headerList,
    async () => {
      const response = await Http.get(HOME_API_URL.headerList);
      setHeaderMegaMenuNavigation(response.data);
    }, {
    refetchOnWindowFocus: false,
  });


  const getWishListAPI = useMemo(() => ["getWishListAPI"], []);
  const { data: getWishList } = useQuery(
    authState == true ? getWishListAPI : null,
    async () => {
      const response = await Http.get(PRODUCT_API_URL.getProductWishList);
      return response?.data;
    },
    {
      enabled: authState, // Only enable the query when isAuthenticated is true
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    authStateSet(isAuthenticated)
  }, [isAuthenticated])

  const handleDivClick = (productId) => {
    setSearchText('');
    setProductList([])
    navigate(PATH_PRODUCT.productDetailNew(base64.encode(productId)), { state: { productId: productId } });
  };

  return (
    <header className={`front-header fixed top-0 start-0 z-50 end-0 transition-all duration-500 group  ${hasScrolled ? "lg:-translate-y-[90px] hasScrolled" : "!translate-y-0"}`}>
      <div className="header-sub1 py-4 bg-white lg:py-6">
        <div className="container">
          <div className="header-wrapper flex items-center lg:justify-end justify-between">
            {/* <Link
              className="text-14 font-nunito text-dark font-500  items-center gap-1 lg:flex hidden pt-[2px] group/link min-w-[250px]"
              to={"/contact-us"}
            >
              <span>
                <IconPhone className="w-4 h-4 transition-all duration-300 text-dark group-hover/link:!text-black" />
              </span>{" "}
              {t("front_header.contact_us")}
            </Link> */}
            <div className="lg:hidden flex items-start min-w-[78px]">
              <div className=" close-menu text-end leading-1">
                <button
                  className="border-0 bg-transparent mt-1"
                  onClick={() => handleToogleSidebar()}
                >
                  <IconMenu className="w-5 h-5 text-dark" />
                </button>
              </div>
            </div>
            <div className="logo-part absolute left-1/2 -translate-x-1/2 flex items-center flex-col  gap-1 sm:gap-3">
              <Link to={"/"}>
                <Logo className="text-dark w-[194.64px] h-[32px] xsm:max-w-[140px] max-w-[120px] lg:max-w-full" />
              </Link>
              {/* <h5 className="xsm:text-xs text-[10px] uppercase  tracking-[8px]">Kuwait</h5> */}
            </div>
            <div className="action-part pt-[2px] flex items-center gap-4">
              <div className="lg:flex hidden">
                <LanguageSelect hasScrolled={hasScrolled} />
              </div>
              <ul className="flex items-center gap-sm leading-1">
                <li>
                  <button
                    className="group/link relative"
                    title={t("front_header.cart")}
                    onClick={() => handleToogleCartSidebar()}
                  >
                    <IconCart className="w-5 h-5  transition-all duration-300 text-dark group-hover/link:!text-black" />
                    {cartItemCount > 0 ?
                      <span className="w-3.5 h-3.5 rounded-full flex justify-center items-center bg-red-500 absolute -top-2 -right-1 text-[10px] text-white">{cartItemCount}</span> : ''}
                  </button>
                </li>
                <li>
                  <Link
                    className="group/link relative"
                    title={t("front_header.wishlist")}
                    to={PATH_PROFILE.ourWishList}>
                    <IconHeart className="w-5 h-5 transition-all duration-300 text-dark group-hover/link:!text-black" />
                    {getWishList?.length > 0 ? <span className="w-3.5 h-3.5 rounded-full flex justify-center items-center bg-red-500 absolute -top-2 -right-1 text-[10px] text-white">{getWishList?.length}</span> : ''}

                  </Link>
                </li>
                <li className="lg:block hidden">
                  <Link className="group/link" title={t("front_header.my_account")} to={PATH_PROFILE.profile}>
                    <IconProfile className="w-5 h-5  transition-all duration-300 text-dark group-hover/link:!text-black" />
                  </Link>
                </li>
                <div className="category-search-toogle  lg:hidden block">
                  <button
                    className="bg-transparent border-none group/searchtoogle relative py-3"
                    onClick={() => setSearchToogleActive(!searchToogleActive)}
                  >
                    {searchToogleActive ? (
                      <IconClose2
                        className={`w-5 h-5 text-black  transition-all duration-300  group-hover/searchtoogle!text-black`}
                      />
                    ) : (
                      <IconSearch2 className="w-5 h-5  transition-all duration-300  group-hover/searchtoogle:   !text-black" />
                    )}
                  </button>
                  <div
                    className={`absolute bg-white w-full py-4 left-0 top-[80px] border-t border-gray-mid shadow-search-toggle overflow-auto max-h-[calc(100vh-78px)] transition-all duration-300 ${searchToogleActive
                      ? "mt-0 opacity-100 visible"
                      : "opacity-0 invisible mt-4"
                      }`}
                  >
                    <div className="container">
                      <div className="search-part">
                        <div className="form-item relative">
                          <input
                            type="text"
                            className="outline-none text-black  !text-14 font-400  ps-8  w-full  placeholder:text-black  placeholder:!text-14 placeholder:capitalize"
                            placeholder={t("input_filed_placeholder.search_product")}
                            onKeyDown={(e) => handleSearch(e)}
                            onChange={(e) => handleSuggestionSearch(e)}
                          />
                          <span className="absolute left-0 top-1/2 -translate-y-1/2">
                            <IconSearch2 className="w-5 h-5 text-black" />
                          </span>
                        </div>
                      </div>
                      {searchText && <div className="grid md:grid-cols-2 lg:hidden gap-[20px] md:gap-md my-4">
                        {
                          productList.length > 0 ? productList.map((product) =>
                            <div className={`card-item flex items-center gap-lg`} >
                              <div className="flex w-full min-h-[inherit]" onClick={() => handleDivClick(product._id)}>
                                <div className={`img-part flex-shrink-0 max-sm:col-span-2 flex items-center justify-center relative bg-no-repeat bg-contain bg-center cursor-pointer`}>
                                  <img src={files(product.cover_image, 'thumbnail')} alt="" className='sm:w-[140px] sm:h-[140px] w-[120px] h-[120px] mx-auto object-contain' />
                                </div>
                                <div className="p-2.5 lg:p-4 max-sm:col-span-3 flex items-center justify-between">
                                  <div className="text-content flex-grow overflow-hidden">
                                    <h2 className='font-nunito sm:text-base-18 text-base text-dark sm:mb-2 mb-1 hover:font-400 cursor-pointer'>{language == 'ar' ? product.ar_name : product.name}</h2>
                                    <h2 className='font-nunito sm:text-base text-sm text-dark line-clamp-2 sm:mb-2 mb-1 hover:font-400 cursor-pointer'>{language == 'ar' ? product.ar_description : product.description}</h2>
                                    <h5 className='!font-cardillac text-base-18 text-dark font-500'>{product.regular_price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 })}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                            : <div className="no-content sm:py-20 py-12">
                              <h2 className='font-nunito text-base-18 text-dark sm:mb-2 mb-1 hover:font-400 cursor-pointer'>{t("comman_text.no_products_for_query")} "{searchText}"</h2>
                              <Link to="/search-results" className="font-nunito underline">
                                {t("button.see_all_products")}
                              </Link>
                            </div>
                        }
                      </div>}
                      {productList.length > 0 && <Button onClick={handleViewAll} className='font-nunito w-full sm:max-w-[200px] mx-auto lg:!text-base text-sm max-lg:py-3.5 flex items-center justify-center' size={"sm"}>
                        {t("button.view_all")}
                      </Button>}
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header-sub2  group-[.hasScrolled]:!bg-white group-[.hasScrolled]:lg:!bg-black bg-white transition-all duration-500 menu-part xl:block ${widthSize < 992 ? mobileClass : ""
          }`}
      >
        <div className="menu-part lg:h-[unset] h-screen lg:overflow-y-[unset] overflow-y-auto hidden-scrollbar">
          <div className="container max-[992px]:max-w-full max-[992px]:p-0">
            <div className="lg:hidden mobile-menu-head h-[50px] bg-white z-50 sticky top-0 left-0 text-end mb-4 flex items-center justify-between border-b border-gray-mid">
              <button
                className={`${activeNavLink != null ? "flex" : "hidden"
                  } border-e border-gray-mid bg-transparent w-[50px] h-[50px]  items-center justify-center`}
                onClick={() => { setActiveNavLink(null); setarActiveNavLink(null) }}
              >
                <IconArrow className="w-5 h-5 text-dark rotate-180 rtl:rotate-0" />
              </button>
              <div className="current-menu-title uppercase text-base font-600">
                {language == 'ar' ? aractiveNavLink : activeNavLink}
              </div>
              <button
                className="border-0 bg-transparent w-[50px] h-[50px] flex items-center justify-center border-s border-gray-mid"
                onClick={handleMenuClose}
              >
                <IconClose2 className="w-5 h-5 text-dark" />
              </button>
            </div>
            <div className="about-profile mb-6 lg:hidden px-6">
              <Link to={`${isAuthenticated ? PATH_PROFILE.profile : PATH_AUTH.login}`}>
                <div className="p-4 bg-black rounded-sm flex items-center gap-4">
                  <div className="avatar w-12 h-12 rounded-full overflow-x-hidden flex items-center justify-center">
                    {profile ? <img src={files(profile, "profile")} alt="" className='w-full h-full text-white object-fill object-center' /> : <IconProfile className="w-5 h-5 text-white" />}
                  </div>
                  <div className="text-part flex-grow">
                    {/* {user.first_name ? <h5 className="text-white text-base font-500">{user.first_name}</h5> : <h5 className="text-white text-base font-500">{user.first_name + " " + user.last_name}</h5>} */}
                    {firstName ? <h5 className="text-white text-base font-500">{firstName + " " + lastName}</h5> : <h5 className="text-white text-base font-500">{t("front_header.my_account")}</h5>}
                    <p className="text-white text-sm font-500">
                      {email}
                    </p>
                  </div>
                  <span>
                    <IconArrow className="w-5 h-5 text-white rtl:rotate-180" />
                  </span>
                </div>
              </Link>
            </div>
            <div className="flex items-center gap-8">
              <ul className="flex lg:items-center lg:flex-row flex-col gap-0 lg:justify-between  w-full lg:mx-auto">
                <li className="group/submenu">
                  <Link to={"/"} className="leading-[50px] group-[.hasScrolled]:!text-black group-[.hasScrolled]:lg:!text-white flex items-center justify-between lg:leading-1.2 h-[50px] lg:h-auto text-sm rtl:!font-arabic  font-00 text-dark  transition duration-300 uppercase before:lg:block before:hidden before:absolute before:left-1/2 before:transition-all before:duration-300 before:h-[2px] before:bg-black before:bottom-0 before:lg:bottom-3 before:w-0 group-hover/submenu:!text-black group-hover/submenu:before:w-full group-hover/submenu:before:left-0 lg:py-4 px-6 lg:px-0 relative">
                    {t('front_footer.home')}
                  </Link>
                </li>
                {headerMegaMenuNavigation.length ? headerMegaMenuNavigation?.map((nav, index) => (
                  <li
                    className={`${nav.hasSubMenu && "has-sub-menu group/submenu"
                      }`}
                    key={index}
                  >
                    <Link
                      role="button"
                      onClick={() =>
                        widthSize < 992 &&
                        nav.hasSubMenu &&
                        handleNavClick(nav.actionLink, nav.ar_name)
                      }
                      className="leading-[50px] group-[.hasScrolled]:!text-black group-[.hasScrolled]:lg:!text-white flex items-center justify-between lg:leading-1.2 h-[50px] lg:h-auto text-sm rtl:!font-arabic  font-00 text-dark  transition duration-300 uppercase before:lg:block before:hidden before:absolute before:left-1/2 before:transition-all before:duration-300 before:h-[2px] before:bg-black before:bottom-0 before:lg:bottom-3 before:w-0 group-hover/submenu:!text-black group-hover/submenu:before:w-full group-hover/submenu:before:left-0 lg:py-4 px-6 lg:px-0 relative"
                    >
                      {language == 'ar' ? nav.ar_name : nav.actionLink}
                      {nav.hasSubMenu && nav.menuComponent ? (
                        <span className="flex-shrink-0 lg:hidden">
                          <IconArrow className="w-5 h-5 rtl:rotate-180" />
                        </span>
                      ) : null}
                    </Link>
                    {nav.hasSubMenu
                      ? cloneElement(<JewelleryMegaMenu categoryList={nav.category} collectionList={nav.collection} />, {
                        actionLink: nav.actionLink,
                        activeNavLink: activeNavLink,
                        setActiveNavLink: setActiveNavLink,
                        searchToogleActive: searchToogleActive,
                      })
                      : null}
                  </li>
                )) : <></>}
                {HeaderMegaMenuNavigation?.map((nav, index) => (
                  <li
                    className={`${nav.hasSubMenu && "has-sub-menu group/submenu"
                      }`}
                    key={index}
                  >
                    <Link
                      role="button"
                      onClick={() =>
                        widthSize < 992 &&
                        nav.hasSubMenu &&
                        handleNavClick(nav.actionLink, nav.arText)
                      }
                      className="leading-[50px] group-[.hasScrolled]:!text-black group-[.hasScrolled]:lg:!text-white flex items-center justify-between lg:leading-1.2 h-[50px] lg:h-auto text-sm rtl:!font-arabic  font-500 text-dark  transition duration-300 uppercase before:lg:block before:hidden before:absolute before:left-1/2 before:transition-all before:duration-300 before:h-[2px] before:bg-black before:bottom-0 before:lg:bottom-3 before:w-0 group-hover/submenu:!text-black group-hover/submenu:before:w-full group-hover/submenu:before:left-0 lg:py-4 px-6 lg:px-0 relative"
                    >
                      {language === 'ar' ? nav.arText : nav.actionLink}
                      {/* {nav.hasSubMenu && nav.menuComponent ? (
                        <span className="flex-shrink-0 lg:hidden">
                          <IconArrow className="w-5 h-5 rtl:rotate-180" />
                        </span>
                      ) : null} */}
                    </Link>
                    {nav.hasSubMenu
                      ? cloneElement(nav.menuComponent, {
                        actionLink: nav.actionLink,
                        activeNavLink: activeNavLink,
                        setActiveNavLink: setActiveNavLink,
                        searchToogleActive: searchToogleActive,
                      })
                      : null}
                  </li>
                ))}
                {widthSize < 992 && isAuthenticated ?
                  <li
                  >
                    <Link
                      role="button"
                      onClick={() =>
                        widthSize < 992 &&
                        handleLogout()
                      }
                      className="leading-[50px] group-[.hasScrolled]:!text-black group-[.hasScrolled]:lg:!text-white flex items-center justify-between lg:leading-1.2 h-[50px] lg:h-auto text-sm font-500 text-dark  transition duration-300 uppercase before:lg:block before:hidden before:absolute before:left-1/2 before:transition-all before:duration-300 before:h-[2px] before:bg-black before:bottom-0 before:lg:bottom-3 before:w-0 group-hover/submenu:!text-black group-hover/submenu:before:w-full group-hover/submenu:before:left-0 lg:py-4 px-6 lg:px-0 relative"
                    >
                      {t("button.logout")}
                    </Link>
                  </li>
                  :
                  <></>}
                <li className="group/submenu">
                  <Link to={"/contact-us"} className="leading-[50px] group-[.hasScrolled]:!text-black group-[.hasScrolled]:lg:!text-white flex items-center justify-between lg:leading-1.2 h-[50px] lg:h-auto text-sm rtl:!font-arabic  font-500 text-dark  transition duration-300 uppercase before:lg:block before:hidden before:absolute before:left-1/2 before:transition-all before:duration-300 before:h-[2px] before:bg-black before:bottom-0 before:lg:bottom-3 before:w-0 group-hover/submenu:!text-black group-hover/submenu:before:w-full group-hover/submenu:before:left-0 lg:py-4 px-6 lg:px-0 relative">
                    {t('front_header.contact_us')}
                  </Link>
                </li>
              </ul>
              <div className="category-search-toogle lg:block hidden">
                <button
                  className="bg-transparent border-none group/searchtoogle relative py-3"
                  onClick={() => setSearchToogleActive(!searchToogleActive)}
                >
                  {searchToogleActive ? (
                    <IconClose2
                      className={`w-5 h-5 text-black group-[.hasScrolled]:!text-black group-[.hasScrolled]:lg:!text-white  transition-all duration-300  group-hover/searchtoogle!text-black`}
                    />
                  ) : (
                    <IconSearch2 className="w-5 h-5 group-[.hasScrolled]:!text-black group-[.hasScrolled]:lg:!text-white  transition-all duration-300  group-hover/searchtoogle:!text-black" />
                  )}
                </button>
                <div
                  className={`absolute bg-white w-full py-4 left-0 border-t border-gray-mid shadow-search-toggle transition-all duration-300 ${searchToogleActive
                    ? "mt-0 opacity-100 visible"
                    : "opacity-0 invisible mt-4"
                    }`}
                >
                  <div className="container">
                    <div className="search-part lg:block hidden">
                      <div className="form-item relative">
                        <input
                          type="text"
                          className="outline-none text-black  !text-14 font-400  ps-8  w-full  placeholder:text-black  placeholder:!text-14 placeholder:capitalize"
                          placeholder={t("input_filed_placeholder.search_product")}
                          onKeyDown={(e) => handleSearch(e)}
                          onChange={(e) => handleSuggestionSearch(e)}
                        />
                        <span className="absolute left-0 top-1/2 -translate-y-1/2">
                          <IconSearch2 className="w-5 h-5 text-black" />
                        </span>
                      </div>
                    </div>
                    {searchText && <div className="lg:grid md:grid-cols-3 hidden gap-sm md:gap-md my-4">
                      {
                        productList.length > 0 ? productList.map((product) =>
                          <div className={`card-item flex items-center gap-lg`}>
                            <div className="flex w-full min-h-[inherit]" onClick={() => handleDivClick(product._id)}>
                              <div className={`img-part flex-shrink-0 max-sm:col-span-2 flex items-center justify-center relative bg-no-repeat bg-contain bg-center cursor-pointer`} >
                                <img src={files(product.cover_image, 'thumbnail')} alt="" className='xl:w-[180px] w-[140px] xl:h-[180px] h-[140px] mx-auto object-cover object-center' />
                              </div>
                              <div className="p-4 max-sm:col-span-3 flex items-center justify-between">
                                <div className="text-content flex-grow overflow-hidden">
                                  <h2 className='font-nunito text-base-18 text-dark sm:mb-2 mb-2 hover:font-400 cursor-pointer'>{language == 'ar' ? product.ar_name : product.name}</h2>
                                  <h2 className='font-nunito text-base text-dark line-clamp-2 sm:mb-2 mb-2 hover:font-400 cursor-pointer'>{language == 'ar' ? product.ar_description : product.description}</h2>
                                  <h5 className='!font-cardillac text-base-18 text-dark font-500'>{product.regular_price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 })}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                          : <div className="no-content sm:py-20 py-12">
                            <h2 className='font-nunito text-base-18 text-dark sm:mb-2 mb-2 hover:font-400 cursor-pointer'>{t("comman_text.no_products_for_query")} "{searchText}"</h2>
                            <Link to="/search-results" className="font-nunito underline">
                              {t("button.see_all_products")}
                            </Link>
                          </div>
                      }
                    </div>}
                    {productList.length > 0 && <Button onClick={handleViewAll} className='font-nunito w-full sm:max-w-[200px] mx-auto lg:!text-base text-sm max-lg:py-3.5 flex items-center justify-center' size={"sm"}>
                      {t("button.view_all")}
                    </Button>}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:hidden block px-6 py-2">
              <LanguageSelect />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default withTranslation()(FrontHeader);
