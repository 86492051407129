import React, { createContext, useContext, useEffect, useState } from "react";

const Maincontext = createContext();

const Context = ({ children }) => {
  const [isRouteActive, setisRouteActive] = useState(true);
  const [widthSize, setWidthSize] = useState(0);

  let resizeWindow = () => {
    setWidthSize(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    widthSize < 992 && setisRouteActive(false)
  }, [widthSize])

  return (
    <Maincontext.Provider value={{ isRouteActive, setisRouteActive, widthSize }}>
      {children}
    </Maincontext.Provider>
  )
};

export const ContextState = () => {
  return useContext(Maincontext);
};

export default Context;
