import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import IconArrowTail from '../svg/IconArrowTail'
// import useCollection from '../../hooks/useCollection'
// import useCategory from '../../hooks/useCategory'
import files from '../../helpers/helper'
// import useCMS from '../../hooks/useCms';
import { Tab } from '@headlessui/react'
import useLanguage from '../../hooks/useLanguage'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const JewelleryMegaMenu = ({ activeNavLink, setActiveNavLink, actionLink, searchToogleActive, categoryList, collectionList }) => {

    // const { collectionList } = useCollection()
    // const { categoryList } = useCategory()

    // const { cmsList } = useCMS()
    const { language } = useLanguage()
    const [categories, setCategories] = useState({});


    // let [categories] = useState({
    //     ...(categoryList.length > 0 && { [categoryLang]: categoryList }),
    //     ...(collectionList.length > 0 && { Collection: collectionList }),
    // });

    useEffect(() => {
        const categoryLang = language === 'ar' ? 'الفئات' : 'Categories';
        const collectionLang = language === 'ar' ? 'المجموعة' : 'Collection';

        setCategories({
            ...(categoryList.length > 0 && { [categoryLang]: categoryList }),
            ...(collectionList.length > 0 && { [collectionLang]: collectionList }),
        });
    }, [language, categoryList, collectionList]);

    return (
        <div className={`transition-all duration-200 hidden-scrollbar h-full ease-in ${actionLink === activeNavLink ? "opacity-100 visible translate-x-0" : "opacity-0 invisible translate-x-full"} pt-[74px] max-xsm:pb-0 top-0 h-full lg:top-[unset] lg:h-auto lg:pt-6 lg:translate-x-0 overflow-y-auto theme-scrollbar sub-menu absolute  shadow-mega-menu ${searchToogleActive ? "border-t-0" : "border-t border-gray-mid"} z-10  bg-white p-8 w-full start-0 lg:mt-2 transition-all duration-300 lg:opacity-0 lg:invisible group-hover/submenu:lg:mt-0  group-hover/submenu:lg:opacity-100 group-hover/submenu:lg:visible`}>
            <div className="container max-[992px]:max-w-full max-[992px]:p-0">
                <div className="menu-wrapper">
                    <div className="">
                        <Tab.Group>
                            <Tab.List className="flex p-0 gap-8">
                                {Object.keys(categories).map((category, index) => (
                                    <Tab
                                        key={index}
                                        className={({ selected }) =>
                                            classNames(
                                                'last:border-0 relative text-sm font-500 outline-0 uppercase after:w-0.5 after:h-3/4 after:top-1/2 after:-end-[18px] after:-translate-y-1/2 after:!bg-[#101010]/20 after:absolute last:after:hidden',
                                                selected
                                                    ? 'text-dark before:absolute before:h-[1px] before:w-[100%] before:start-0 before:-bottom-[2px]  before:bg-black'
                                                    : 'text-gray-dark hover:text-dark'
                                            )
                                        }
                                    >
                                        {category}
                                    </Tab>
                                ))}
                            </Tab.List>
                            <Tab.Panels className="mt-6">
                                {Object.values(categories).map((val, idx) => (
                                    <Tab.Panel
                                        key={idx}
                                    >
                                        <div className="grid grid-cols-1 lg:grid-cols-5 xl:grid-cols-6 gap-4 max-xsm:pb-28">
                                            {val.map((value, index) => (
                                                <Link
                                                    to={value?.image ? "/category-products" : "/search-results"}
                                                    key={index} state={{ categoryId: value?.categoryImage ? value._id : undefined, collectionId: value?.image ? value._id : undefined, collectionName: value?.name, ar_collectionName: value?.ar_name }} className="text-base font-500 text-dark flex items-center gap-3">
                                                    <div className="img-ads w-full cursor-pointer">
                                                        <div className="shop-ad-card">
                                                            <div className="img-part h-[180px] lg:w-[180px]  relative mb-4">
                                                                {value?.categoryImage && <img src={files(value?.categoryImage, 'category')} alt="" className="w-full h-full object-cover object-center" />}
                                                                {value?.image && <img src={files(value?.image, 'collection')} alt="" className="w-full h-full object-cover object-center" />}
                                                            </div>
                                                            <div>
                                                                {language == 'ar' ? value?.ar_name : value?.name}{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </Tab.Panel>
                                ))}
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JewelleryMegaMenu