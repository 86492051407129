import React, { forwardRef } from 'react';
import { merge } from '../../lib/utils';
import ErrorMessagePara from '../common/ErrorMessage';

const Input = forwardRef(({ RootClassname, className, errorMsg, placeholder, suffix, prefix, type, errorType, ...props }, ref) => {
    const { helpertext, name } = props
    return (
        <div className='flex flex-col w-full'>
            <div className={merge("relative", RootClassname)}>
                {prefix && <span className='absolute top-1/2 -translate-y-1/2 start-4 '>{prefix}</span>}
                <input name={name} autoComplete="new-password" type={type ? type : "text"} ref={ref} className={merge(`py-4 pe-4 group ${prefix ? "ps-14" : "ps-4"} ${suffix ? "pe-12" : "pe-4"} bg-gray-light border border-gray-mid rounded-sm   focus:border-gray/60 focus:text-black placeholder:focus:text-black  outline-none  focus:bg-gray-mid/10 text-base text-gray-dark font-400 w-full`, className)} placeholder={placeholder} {...props} />
                {suffix && <span className='absolute top-1/2 -translate-y-1/2 end-4'>{suffix}</span>}
            </div>
            <ErrorMessagePara errorType={helpertext} />
        </div>
    )
})

export default Input