import React from 'react'

const IconSearch2 = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40" fill="none">
<path d="M18.2299 3.21888C14.2558 3.22555 10.4464 4.80721 7.63624 7.61734C4.82612 10.4275 3.24446 14.2369 3.23779 18.211C3.24111 22.1885 4.8209 26.0024 7.63103 28.8173C10.4412 31.6321 14.2525 33.2183 18.2299 33.2283C21.7575 33.2283 25.0079 31.9874 27.5779 29.9338L33.8205 36.1764C34.1367 36.4704 34.5546 36.6305 34.9864 36.6229C35.4181 36.6153 35.8302 36.4407 36.1359 36.1358C36.4417 35.8309 36.6174 35.4193 36.6261 34.9876C36.6348 34.5558 36.4759 34.1375 36.1827 33.8205L29.9402 27.5716C32.0722 24.92 33.2345 21.6197 33.2346 18.2173C33.2346 9.95274 26.4945 3.21888 18.2299 3.21888ZM18.2299 6.55747C24.6929 6.55747 29.8961 11.7543 29.8961 18.211C29.8961 24.6677 24.6929 29.896 18.2299 29.896C11.7669 29.896 6.57008 24.6866 6.57008 18.2236C6.57008 11.7606 11.7669 6.55747 18.2299 6.55747Z" fill="currentcolor"/>
</svg>
  )
}

export default IconSearch2