import { Dialog, Transition } from '@headlessui/react';
import { Fragment,useState } from 'react';
import IconClose from '../svg/IconClose';
import { Button } from '../ui/Button';
import { Form, FormikProvider, useFormik } from "formik";
import Input from '../ui/Input';
import Http from "../../api/Http";
import AdvanceFileUpload from '../ui/AdvanceFileUpload';
import { USER_API_URL } from '../../api/path'
import { useMutation,useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { ticketSupport } from '../../validation/validation';
import IconUpload from '../svg/IconUpload'



const AddTicketModal = ({ addTicketModalOpen, setAddTicketModalOpen }) => {

    const queryClient = useQueryClient();   
    const [newsFile, setNewsFile] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("No file chosen"); 

    const handleClose = () => {
        resetForm();
        setNewsFile();
        setSelectedFileName();
        setAddTicketModalOpen(false)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
          title: "",
          message: ""
        },
        validationSchema: ticketSupport,
        onSubmit: async (values) => {
            values.file = newsFile
        //   setIsLoading(true);
          await changePassword(values);
        },
      });
    
      const { mutateAsync: changePassword } = useMutation(
        async (data) => {
          return await Http.post(USER_API_URL.ticketCreate, data);
        },
        {
          onSuccess: async ({ data }) => {
            queryClient.invalidateQueries(["ticketApi"]);
            setAddTicketModalOpen(false)
            resetForm();
            setNewsFile();
            setSelectedFileName();
            enqueueSnackbar(data.message, {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          },       
        }
    );
    
    const {
        handleSubmit,
        getFieldProps,
        setFieldError,
        touched,
        errors,
        resetForm,
    } = formik;

    const convertFileToBase64 = (file) => {
        const reader = new FileReader();
    
        reader.onload = (e) => {
          const base64 = e.target.result;
          setNewsFile(base64)
        };
    
        reader.readAsDataURL(file);
      };

    const handleOnChange = (e) => {
        const fileList = e.target.files[0];
        if (fileList) {
          setSelectedFileName(fileList.name);
          convertFileToBase64(fileList);
        } else {
          setSelectedFileName("No file chosen");
        }
      }

    return (
        <FormikProvider value={formik}>
        <form autoComplete="off" onSubmit={handleSubmit}>
        <Transition appear show={addTicketModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => handleClose()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>               
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg transform rounded-sm bg-white shadow-xl transition-all overflow-hidden">
                                <div className="dialog-content">
                                    <div className='dialog-header flex items-center justify-center px-6 py-4 bg-gray-light '>
                                        <h5 className="text-base-20 font-500 text-center text-dark capitalize">
                                            Add New Ticket
                                        </h5>
                                        
                                        <button className='close cursor-pointer absolute top-4 end-6' onClick={handleClose}>
                                            <IconClose className="w-5 sm:w-6 h-5 sm:h-6 text-gray-dark " />
                                        </button>
                                    </div>
                                    <div className='dialog-body text-center p-6'>
                                        <form action="">
                                            <div className="field-part mb-6">
                                                <div className="form-item mb-4 text-start">
                                                    <Input placeholder="Ticket Title"
                                                     name="title"
                                                     {...getFieldProps("title")}
                                                     error={touched.title && errors.title}
                                                     helpertext={touched.title && errors.title}
                                                     />
                                                    
                                                </div>
                                                <div className="form-item mb-4 text-start">
                                                    <Input placeholder="Write Message..."
                                                    name="message"
                                                    {...getFieldProps("message")}
                                                    error={touched.message && errors.message}
                                                    helpertext={touched.message && errors.message}
                                                    />
                                                </div>
                                                <div className="form-item mb-4">
                                                <div className='h-[200px] w-full bg-light rounded-sm border border-gray-mid bg-gray-light '>
                                                    <label htmlFor="dropzone-file" className='flex items-center justify-center flex-col gap-sm h-full' >
                                                        <div className="icon">
                                                            <IconUpload className="h-8 w-8" />
                                                        </div>
                                                        <h5 className='text-dark text-base font-500'>Upload File</h5>
                                                        <p className=' text-dark text-sm font-400'>Upload PDF, PNG or JPG format only</p>
                                                    </label>
                                                    <input
                                                        type='file'
                                                        onChange={handleOnChange}
                                                        id='dropzone-file'
                                                        className='hidden'
                                                        // ref={ref}
                                                    />
                                                        {selectedFileName}
                                                </div>   
                                                </div>
                                            </div>
                                            <div className="action-part">
                                                <Button  className={"sm:max-w-[80%] w-full mx-auto"}>
                                                   Submit
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
        </form>
    </FormikProvider>
    )
}

export default AddTicketModal
