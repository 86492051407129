import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import OurWorld1 from '../../assets/images/our-world-1.webp'
import OurWorld2 from '../../assets/images/our-world-2.webp'
import OurWorld3 from '../../assets/images/our-world-3.webp'
import OurWorld4 from '../../assets/images/our-world-4.png'
import { withTranslation } from 'react-i18next'
import useLanguage from '../../hooks/useLanguage'


const MailArtiWorldMegaMenu = ({ t,activeNavLink, setActiveNavLink,arText, actionLink ,searchToogleActive}) => {
    const { language } = useLanguage()

    return (
        <div className={`transition-all duration-200 ease-in ${actionLink === activeNavLink ? "opacity-100 visible translate-x-0" : "opacity-0 invisible translate-x-full"} pt-20 max-xsm:pb-0 top-0 h-full lg:top-[unset] lg:h-auto lg:pt-8 lg:translate-x-0 overflow-y-auto theme-scrollbar sub-menu absolute  shadow-mega-menu  ${searchToogleActive ? "border-t-0":"border-t border-gray-mid" }  z-10 bg-white p-8 w-full start-0 lg:mt-2 transition-all duration-300 lg:opacity-0 lg:invisible group-hover/submenu:lg:mt-0  group-hover/submenu:lg:opacity-100 group-hover/submenu:lg:visible`}>
            <div className="container max-[992px]:max-w-full max-[992px]:p-0">
                <div className="menu-wrapper">

                    <div className="grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-1 lg:grid-cols-4 gap-6 max-xsm:pb-28">
                        <Link to={""}>
                            <div className="our-world-card">
                                <div className="img-wrapper mb-4 w-full h-[200px] xl:h-[240px] rounded-sm lg:rounded-md overflow-hidden">
                                    <img src={OurWorld1} alt="" className='w-full h-full object-cover object-center' />
                                </div>
                                <h4 className='text-base text-dark font-500 uppercase'>{t("front_header.our_story")}</h4>
                            </div>
                        </Link>
                        <Link to={"/contact-us"}>
                            <div className="our-world-card">
                                <div className="img-wrapper mb-4 w-full h-[200px] xl:h-[240px] rounded-sm lg:rounded-md overflow-hidden">
                                    <img src={OurWorld2} alt="" className='w-full h-full object-cover object-center' />
                                </div>
                                <h4 className='text-base text-dark font-500 uppercase'>{t("front_header.contact")}</h4>
                            </div>
                        </Link>
                        <Link to={"/our-stores"}>
                            <div className="our-world-card">
                                <div className="img-wrapper mb-4 w-full h-[200px] xl:h-[240px] rounded-sm lg:rounded-md overflow-hidden">
                                    <img src={OurWorld3} alt="" className='w-full h-full object-cover object-center' />
                                </div>
                                <h4 className='text-base text-dark font-500 uppercase'>{t("front_header.location")}</h4>
                            </div>
                        </Link>
                        <Link to={"/latest-news"}>
                            <div className="our-world-card">
                                <div className="img-wrapper mb-4 w-full h-[200px] xl:h-[240px] rounded-sm lg:rounded-md overflow-hidden">
                                    <img src={OurWorld4} alt="" className='w-full h-full object-cover object-center' />
                                </div>
                                <h4 className='text-base text-dark font-500 uppercase'>{t('home_module.latest_news')}</h4>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default  withTranslation()(MailArtiWorldMegaMenu)