import { createContext, useEffect, useState } from "react";

const LanguageContext = createContext()

const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));

    useEffect(() => {
        setLanguage(localStorage.getItem('i18nextLng'));
    }, []);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
};

export { LanguageProvider, LanguageContext }
