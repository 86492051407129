import React from 'react'

const IconCart = (props) => {
    return (
        <svg {...props} viewBox="0 0 32 32" fill="none">
            <path d="M9.99977 10.2267V8.93334C9.99977 5.93334 12.4131 2.98667 15.4131 2.70667C16.2467 2.62477 17.0881 2.71831 17.8834 2.98125C18.6786 3.2442 19.41 3.67073 20.0304 4.2334C20.6509 4.79606 21.1466 5.4824 21.4858 6.24823C21.825 7.01406 22.0001 7.84242 21.9998 8.68V10.52M11.9998 29.3333H19.9998C25.3598 29.3333 26.3198 27.1867 26.5998 24.5733L27.5998 16.5733C27.9598 13.32 27.0264 10.6667 21.3331 10.6667H10.6664C4.9731 10.6667 4.03977 13.32 4.39977 16.5733L5.39977 24.5733C5.67977 27.1867 6.63977 29.3333 11.9998 29.3333Z" stroke="currentcolor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.66 16H20.6733M11.3267 16H11.3373" stroke="currentcolor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconCart