import React from 'react'

const IconFacebook = (props) => {
    return (
        <svg {...props} viewBox="0 0 25 25" fill="none">
            <path d="M13.0405 9.02746H17.5544L17.0528 11.0336H13.0405V20.0614H11.0343V11.0336H7.02197V9.02746H11.0343V7.14945C11.0343 5.36091 11.2206 4.71235 11.5703 4.05848C11.9199 3.40462 12.4331 2.89146 13.0869 2.54178C13.7408 2.19208 14.3893 2.00586 16.1779 2.00586C16.7016 2.00586 17.1604 2.05601 17.5544 2.15632V4.01203H16.1779C14.8502 4.01203 14.4458 4.09011 14.033 4.31084C13.7288 4.47356 13.502 4.70034 13.3393 5.0046C13.1185 5.41732 13.0405 5.82167 13.0405 7.14945V9.02746Z" fill="currentcolor" />
        </svg>
    )
}

export default IconFacebook