import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PATH_AUTH } from '../routes/path';
import useStore from '../contexts/AuthProvider';


// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const state = useStore()
  const isAuthenticated = state.isAuthenticated;

  if (!isAuthenticated) {
    return <Navigate to={PATH_AUTH.login} replace />;
  }

  return <>{children}</>;
}
