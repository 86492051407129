import React, { lazy, Suspense } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Loader from "../components/common/Loader";
import FrontMaster from "../layouts/front/FrontMaster";
import AuthMaster from "../layouts/auth/AuthMaster";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import AddressDetail from "../pages/front/AddressDetail";
import TicketDetail from "../pages/front/TicketDetail";
import ProductDetailsGuard from "../guards/ProductDetailsGuard";
import AddTicket from "../pages/front/AddTicket";
import PrivacyPolicy from "../pages/front/PrivacyPolicy";
import TermsAndConditon from "../pages/front/TermsAndConditon";
import LatestNews from "../pages/front/LatestNews";

const ProductDetails = lazy(() => import("../pages/front/ProductDetails"));
const Wishlist = lazy(() => import("../pages/front/Wishlist"));
const Checkout = lazy(() => import("../pages/front/Checkout"));
const OrderTrack = lazy(() => import("../pages/front/OrderTrack"));
const Profile = lazy(() => import("../pages/front/Profile"));
const PersonalInformation = lazy(() => import("../pages/front/PersonalInformation"));
const PaymentMethod = lazy(() => import("../pages/front/PaymentMethod"));
const ContactSupport = lazy(() => import("../pages/front/ContactSupport"));
// const MyReview = lazy(() => import("../pages/front/MyReview"));
const OrderHistory = lazy(() => import("../pages/front/OrderHistory"));
const Home = lazy(() => import("../pages/front/Home"));
const ContactUs = lazy(() => import("../pages/front/ContactUs"));
const OurStores = lazy(() => import("../pages/front/OurStores"));
const SearchResults = lazy(() => import("../pages/front/SearchResults"));
const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const ForgetPassword = lazy(() => import("../pages/auth/ForgetPassword"));
const OtpVerification = lazy(() => import("../pages/auth/OtpVerification"));
const ChangePassword = lazy(() => import("../pages/auth/ChangePassword"));
const FrontChangePassword = lazy(() => import("../pages/front/ChangePassword"));
const CategoryProducts = lazy(() => import("../pages/front/CategoryProducts"));
const NotFound = lazy(() => import("../pages/Page404"));

export default function Router() {
    const element = useRoutes([
        {
            path: "/",
            element: <FrontMaster />,
            children: [
                { index: true, element: <Home /> },
                { path: "product-detail/:productId", element: (<ProductDetailsGuard><ProductDetails /></ProductDetailsGuard>) },
                { path: "category-products", element: <CategoryProducts /> },
                { path: "wishlist", element: <AuthGuard><Wishlist /></AuthGuard> },
                { path: "checkout", element: <AuthGuard><Checkout /></AuthGuard> },
                { path: "ordertrack", element: <AuthGuard><OrderTrack /></AuthGuard> },
                { path: "contact-us", element: <ContactUs /> },
                { path: "terms-and-condition", element: <TermsAndConditon /> },
                { path: "privacy-policy", element: <PrivacyPolicy /> },
                { path: "our-stores", element: <OurStores /> },
                { path: "search-results", element: <SearchResults /> },
                { path: "latest-news", element: <LatestNews /> },
                {
                    path: "profile",
                    element: (<AuthGuard><Profile /></AuthGuard>),
                    children: [
                        { index: true, element: <PersonalInformation /> },
                        { path: 'change-password', element: <FrontChangePassword /> },
                        { path: 'order-history', element: <OrderHistory /> },
                        { path: 'payment-method', element: <PaymentMethod /> },
                        { path: 'contact-support', element: <ContactSupport /> },
                        { path: 'add-ticket', element: <AddTicket /> },
                        // { path: 'my-review', element: <MyReview /> },
                        { path: 'address', element: <AddressDetail /> },
                        { path: 'ticket-detail', element: <TicketDetail /> },
                    ],
                }
            ]
        },
        {
            path: "/",
            element: (<GuestGuard><AuthMaster /></GuestGuard>),
            children: [
                { path: "login", element: <Login /> },
                { path: "register", element: <Register /> },
                { path: "forget-password", element: <ForgetPassword /> },
                { path: "Otp-verification", element: <OtpVerification /> },
                { path: "change-password", element: <ChangePassword /> }
            ]
        },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);

    return (
        <Suspense fallback={<Loader />}>
            {element}
        </Suspense>
    );
}
