import { cva } from 'class-variance-authority'
import React from 'react'
import { merge } from '../../lib/utils'

const buttonVariants = cva(
    'relative z-1 text-center  outline-none  !text-sm font-600 font-futura uppercase rtl:!font-arabic overflow-hidden disabled:bg-gray-dark',
    {
        variants: {
            variant: {
                "gold":"bg-black !text-white transition-all border-0  btn-theme duration-500 transition-all duration-300 before:transition-all before:duration-300  before:!-z-[1] before:absolute before:top-0 before:start-0 before:w-full before:h-full sm:before:bg-[#595758]",
                "outline":"bg-transparent border border-black/50 roundes-[4px] btn-theme hover:text-white transition-all duration-300 before:transition-all before:duration-300  before:!-z-[1] before:absolute before:top-0 before:start-0 before:w-full before:h-full before:bg-black",
            },
            size: {
                default: "py-3 px-6",
                sm: "py-2 px-4 text-[14px]"
            }
        },
        defaultVariants: {
            variant: "gold",
            size: "default"
        }
    }
)
const Button = ({ children, className, variant, size, ...props }) => {
    return (
        <button className={merge(buttonVariants({ variant, size, className }))} {...props} >
            {children}
        </button>
    )
}

export { Button, buttonVariants }