import React from 'react'

const IconHeart = (props) => {
    
    return (
       
           
            <svg {...props} viewBox="0 0 32 32" fill="none">
                <path d="M16.5357 26.8949L16.5357 26.8949L16.527 26.898C16.4266 26.9334 16.2355 26.9667 15.9998 26.9667C15.7642 26.9667 15.5731 26.9334 15.4727 26.898L15.4727 26.8979L15.4639 26.8949C13.6598 26.279 10.6554 24.647 8.11392 22.043C5.58332 19.4502 3.5665 15.9539 3.5665 11.5867C3.5665 7.95951 6.48776 5.03333 10.0798 5.03333C12.2083 5.03333 14.0922 6.06086 15.2773 7.65659C15.4472 7.88542 15.7155 8.02023 16.0006 8.02C16.2856 8.01977 16.5537 7.88454 16.7232 7.65544C17.3247 6.84291 18.108 6.18252 19.0106 5.72719C19.913 5.27191 20.9095 5.03428 21.9202 5.03333C25.5121 5.03354 28.4332 7.95964 28.4332 11.5867C28.4332 15.9539 26.4164 19.4502 23.8858 22.043C21.3443 24.647 18.3399 26.279 16.5357 26.8949Z" stroke="currentcolor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

    )
}

export default IconHeart