import { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { Link } from 'react-router-dom';
import IconRadioChecked from '../svg/IconRadioChecked';
import IconRadioUnchecked from '../svg/IconRadioUnchecked';
import Http from '../../api/Http';
import { USER_API_URL } from '../../api/path';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const ShippingAddressRadioGroup = ({ t, addressList, refetch, setAddAddressModalOpen, setEditData }) => {
    const queryClient = useQueryClient();
    const [selected, setSelected] = useState(null);
    const location = useLocation();

    const handleSelectedAddress = async (address) => {
        await Http.post(USER_API_URL.addressDefault, { _id: address._id })
            .then(({ data }) => {

                if (location.pathname == "/profile/address") {
                    enqueueSnackbar(data.message, {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
                queryClient.invalidateQueries(["addressApi"]);
                refetch();
            }).catch((error) => {
                enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            });
    }

    const handleEdit = (addressData, event) => {
        setEditData(addressData)
        setAddAddressModalOpen(true)
    }

    useEffect(() => {
        if (addressList.length > 0) {
            const defaultAddress = addressList.find(address => address.isDefault);

            if (defaultAddress) {
                setSelected(defaultAddress);
            }
        }
    }, [addressList]);

    return (
        <div className="w-full">
            <div className="">
                <RadioGroup value={selected} onChange={(address) => { handleSelectedAddress(address) }}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2  gap-4">
                        {addressList.length > 0 ? addressList?.map((address) => (
                            <RadioGroup.Option
                                key={address._id}
                                value={address}
                                className={({ active, checked }) =>
                                    `p-4 sm:p-5 border ${checked ? "border-black" : "border-gray-mid"} transition-all duration-200 rounded-sm block cursor-pointer`
                                }
                            >
                                {({ active, checked }) => (
                                    <>
                                        <div className="flex items-start gap-4">
                                            <span className='mt-1'>
                                                {checked ? <IconRadioChecked className="w-4 h-4" /> : <IconRadioUnchecked className="w-4 h-4" />}
                                            </span>
                                            <div className="text-content flex-grow font-nunito">
                                                <div className="flex items-center gap-4 mb-3">
                                                    <h3 className='flex-grow text-base font-600 text-dark'>{address.firstName} {address.lastName}</h3>
                                                    <Link role='button' className='text-base text-black hover:text-dark' onClick={(event) => handleEdit(address, event)}>{t("button.edit")}</Link>

                                                </div>
                                                {/* <h6 className='text-sm  font-500 text-dark mb-2 text-gray-dark'>{address.firstName} {address.lastName}</h6> */}
                                                <p>{address.address}</p>
                                                <p>{address.streetAddress}</p>
                                                <p>{address.sub_area}</p>
                                                <p>{address.main_area}</p>
                                                <p>{address.city}</p>
                                                <p>{address.state}</p>
                                                <p>{address.country_name}</p>
                                                <p>{address.postalCode}</p>
                                                <p>{address.phoneNumber}</p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </RadioGroup.Option>
                        )) : ""}
                    </div>
                </RadioGroup>
            </div>
        </div>
    )
}

export default withTranslation()(ShippingAddressRadioGroup)