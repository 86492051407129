/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import createContext from "zustand/context";
import Http from "../api/Http";
import { AUTH_API_URL } from "../api/path";
const { Provider, useStore } = createContext();
const middleware_ = (f) =>
  create(
    devtools(
      persist(f, {
        name: "auth-storage",
        storage: createJSONStorage(() => localStorage),
      })
    )
  );
const min = 100000000000; 
const max = 999999999999; 
const random = Math.floor(Math.random() * (max - min + 1)) + min;
const authStore = middleware_((set, get) => ({
  isAuthenticated: false,
  isInitialized: false,
  accessToken: "",
  refreshToken: "",
  user: "",
  isRefreshing: false,
  role: null,
  sessionId: random,
  setInitialized: () => set({ isInitialized: true }),
  login: async (loginObj) => {
    try {
      const response = await Http.post(AUTH_API_URL.login, loginObj);
      set({
        accessToken: response?.data?.accessToken,
        refreshToken: response?.data?.refreshToken,
        user: response?.data?.userData,
        isAuthenticated: true,
      });
      return [response?.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  socialRegister: async (loginObj) => {
    try {
      const response = await Http.post(AUTH_API_URL.registerSocial, loginObj);
      set({
        accessToken: response?.data?.accessToken,
        refreshToken: response?.data?.refreshToken,
        user: response?.data?.userData,
        isAuthenticated: true,

      });
      return [response?.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  register: async (signUpObj) => {
    try {
      const response = await Http.post(AUTH_API_URL.register, signUpObj);
      set({
        accessToken: response?.data?.token,
      });
      return [response?.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  otpVerify: async (verifyObj) => {
    try {
      const response = await Http.post(AUTH_API_URL.otpVerify, verifyObj);
      if (response?.data?.otpVerify == 1) {
        set({
          accessToken: response?.data?.accessToken,
          refreshToken: response?.data?.refreshToken,
          user: response?.data?.userData,
          isAuthenticated: true,
          rememberToken: "",
        });
      }
      return [response?.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  resendOtp: async (resendObj) => {
    try {
      const response = await Http.get(AUTH_API_URL.resendOtp, resendObj);
      return [response?.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  forgetPwd: async (forgetObj) => {
    try {
      const response = await Http.post(AUTH_API_URL.forgetPwd, forgetObj);
      set({
        accessToken: response?.data?.token,
      });
      return [response?.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  resetPwd: async (resetObj) => {
    try {
      const response = await Http.post(AUTH_API_URL.resetPwd, resetObj);
      return [response?.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  logout: () => {
    set({
      user: {},
      accessToken: "",
      refreshToken: "",
      role: null,
      isAuthenticated: false,
      isRefreshing: false,
    });
  },
  updateUserProfile: (data) => {
    set({
      user: data,
    });
  },
}));

const refreshStore = create((set, get) => ({
  isRefreshing: false,
}))

const AuthProvider = ({ children }) => {
  const setInitialized = authStore((state) => state.setInitialized);
  useEffect(() => {
    setInitialized();
  }, []);
  return <Provider createStore={() => authStore}>{children}</Provider>;
};

export { AuthProvider, authStore, refreshStore };
export default useStore;
