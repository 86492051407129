import React from 'react'

const IcnEnglishLang = (props) => {
  return (
<svg {...props} viewBox="0 0 40 40" fill="none">
<path d="M37.0043 6.89648H2.9957C1.34117 6.89648 0 8.23766 0 9.89219V30.1077C0 31.7622 1.34117 33.1034 2.9957 33.1034H37.0043C38.6587 33.1034 40 31.7623 40 30.1077V9.89219C40 8.23766 38.6588 6.89648 37.0043 6.89648Z" fill="#41479B"/>
<path d="M39.9585 9.39703C39.7224 7.97836 38.49 6.89648 37.0043 6.89648H36.2248L23.4483 15.2673V6.89656H16.5517V15.2674L3.77516 6.89656H2.9957C1.51 6.89656 0.277578 7.97836 0.0414844 9.39711L10.9616 16.5518H0V23.4484H10.9616L0.0414844 30.6029C0.277578 32.0216 1.51 33.1034 2.9957 33.1034H3.77516L16.5517 24.7326V33.1034H23.4483V24.7326L36.2248 33.1034H37.0043C38.49 33.1034 39.7224 32.0216 39.9585 30.6029L29.0384 23.4482H40V16.5516H29.0384L39.9585 9.39703Z" fill="#F5F5F5"/>
<path d="M22.069 6.89648H17.931V17.9309H0V22.0689H17.931V33.1034H22.069V22.0689H40V17.9309H22.069V6.89648Z" fill="#FF4B55"/>
<path d="M1.93703 32.9102L16.5138 23.4481H13.9804L0.720703 32.0553C1.04799 32.4378 1.46628 32.7318 1.93703 32.9102ZM27.0616 23.4481H24.5282L38.6466 32.6126C39.0426 32.3524 39.3715 32.0024 39.6066 31.5911L27.0616 23.4481ZM0.316406 8.55258L12.6391 16.5516H15.1725L1.20906 7.4875C0.832448 7.76798 0.526736 8.13274 0.316406 8.55258ZM25.9818 16.5516L39.2658 7.92852C38.9349 7.54697 38.513 7.25514 38.0393 7.08008L23.4484 16.5516H25.9818Z" fill="#FF4B55"/>
</svg>
  )
}

export default IcnEnglishLang
