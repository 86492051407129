import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import authImage from '../../assets/images/auth-img.webp'
import Logo from '../../components/svg/Logo'

const AuthMaster = () => {
  return (
    <main className='auth-main h-screen w-full overflow-y-auto theme-scrollbar'>
      <div className="auth-main-wrapper h-full">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-0">
          <div className="auth-image-part sticky col-span-2 top-0 left-0 h-screen w-full lg:block hidden">
            <img src={authImage} alt='' className='object-cover object-top w-full h-full' />
          </div>
          <div className="auth-form-part col-span-3">
            <div className="bg-dark-950 px-6 sm:px-10 md:px-[60px] xl:px-[100px] xxl:px-[140px] py-[60px] xxl:py-[80px]">
              <div className="auth-form-content">
                <div className="logo-part mb-[60px] text-center">
                  <Link to={"/"}>
                    <Logo className="text-black w-[194.64px] h-[32px] max-w-[140px] lg:max-w-full mx-auto" />
                  </Link>
                </div>
                <div className="auth-pages-form">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AuthMaster