import React, { useState, useMemo, useRef, useEffect } from "react";
import IconArrowRight from "../../components/svg/IconArrowRight";
import { Button } from "../../components/ui/Button";
// import IconAdd from "../../components/svg/IconAdd";
import IconSend from "../../components/svg/IconSend";
import IconUpload2 from "../../components/svg/IconUpload2";
import { ContextState } from "../../context/context";
import AddTicketModal from "../../components/modal/AddTicketModal";
import { useLocation } from 'react-router';
import { FormikProvider, useFormik } from "formik";
import { USER_API_URL } from "../../api/path";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Http from "../../api/Http";
import { ticketReply } from '../../validation/validation';
import files from "../../helpers/helper";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom'
import { PATH_PROFILE } from "../../routes/path";
import { withTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';


const TicketDetail = ({ t }) => {

  const { isRouteActive, setisRouteActive } = ContextState();
  const [addTicketModalOpen, setAddTicketModalOpen] = useState(false);
  const location = useLocation();
  const queryClient = useQueryClient();
  const [newsFile, setNewsFile] = useState("");
  const [selectedFileName, setSelectedFileName] = useState();
  const scroll = useRef(null);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);


  const ticketReplyKey = useMemo(() => ["ticketApi"]);

  const ticket = location.state

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: selectedFileName ? selectedFileName : "",
    },
    validationSchema: ticketReply(t),
    onSubmit: async (values) => {
      const maxFileSizeInBytes = 10 * 1024 * 1024; // 10 MB
      if (newsFile && newsFile.length > maxFileSizeInBytes) {        
        enqueueSnackbar(t("validation_message.file_size_limit"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        return;
      }
  
      if (newsFile) {
        values.message_type = 0;
        values.message = newsFile;
      } else {
        values.message_type = 1;
      }
      values.support_id = ticket?.ticket?._id;
  
      await ticketReplyApi(values);
    },
  });
  

  const { mutateAsync: ticketReplyApi } = useMutation(
    async (data) => {
      setLoader(true);
      return await Http.post(USER_API_URL.ticketReply, data);
    },
    {
      onSuccess: async ({ data }) => {
        setLoader(false);
        queryClient.invalidateQueries(["ticketApi"]);
        setSelectedFileName('')
        setNewsFile('')
        setAddTicketModalOpen(false)
        resetForm();
      },
      onError: (error) => {
        setLoader(false);
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          enqueueSnackbar(errorMessage, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      },
    }
  );

  const { data: replyList } = useQuery(
    ticketReplyKey,
    async () => {
      // setLoader(true);
      const response = await Http.get(`${USER_API_URL.ticketReply}?support_id=${ticket.ticket._id}`);
      // setLoader(false);
      return response?.data;
    }, {
    refetchOnWindowFocus: false,
  });

  const {
    handleSubmit,
    getFieldProps,
    resetForm,
  } = formik;


  const convertFileToBase64 = (file) => {
    const reader = new FileReader();
    setLoader(true)
    reader.onload = (e) => {
      setLoader(false);
      const base64 = e.target.result;
      setNewsFile(base64)
    };
    reader.readAsDataURL(file);
  };

  // const handleOnChange = (e) => {
  //   // previewUserImageOnChange(e)
  //   const fileList = e.target.files[0];
  //   if (fileList) {
  //     setSelectedFileName(fileList.name);
  //     convertFileToBase64(fileList);
  //   } else {
  //     setSelectedFileName("No file chosen");
  //   }
  // }

  const isImageFile = (fileName) => {
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const ext = fileName.split('.').pop().toLowerCase();
    return allowedExtensions.includes(ext);
  };

  const handleOnChange = (e) => {
    setLoader(true);
    const fileList = e.target.files[0];
    e.target.value = null;
    if (fileList) {
      setLoader(true);

      const fileName = fileList.name;
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];

      const fileExtension = fileName.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension) && isImageFile(fileName)) {
        setLoader(false);
        setSelectedFileName(fileName);
        convertFileToBase64(fileList);
      } else {
        setLoader(false);
        enqueueSnackbar(t('validation_message.upload_error'), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      }
    } else {
      setLoader(true);
      setSelectedFileName('No file chosen');
    }
  };

  useEffect(() => {
    srollToBottum();
  }, [replyList]);

  const srollToBottum = () => {
    scroll.current.scrollIntoView({ block: 'end', behavior: 'instant' });
  }

  const handleClick = () => {
    // setAddTicketModalOpen(true);
    navigate(PATH_PROFILE.contactSupport)
  };

  

  return (
    <>
      <Helmet>
        <title>TicketDetail | Mailarti </title>
      </Helmet>
      <div className="lg:border border-gray-mid">
        <div className="header lg:bg-gray-light pt-0 pb-[18px] lg:py-[18px] lg:px-[30px] flex items-center gap-4">
          <button
            onClick={() => setisRouteActive(!isRouteActive)}
            className="lg:hidden flex-shrink-0"
          >
            <IconArrowRight className="text-dark w-4 h-4 rotate-180" />
          </button>
          <div className="flex items-center gap-4 flex-grow">
            <h6 className="text-base font-nunito font-500 text-dark flex-grow">
              {t('button_label.contact_support')}
            </h6>
            {/* <Button
              onClick={() => setAddTicketModalOpen(true)}
              variant={"light"}
              className="py-2 px-3 text-dark flex gap-1 items-center flex-shrink-0"
            >
              <IconAdd className="h-5 w-5" />
              New Ticket
            </Button> */}
            <Button
              onClick={handleClick}
              variant={"light"}
              className="py-2 px-3 !text-sm text-dark flex gap-1 items-center flex-shrink-0"
            >
              {t("button.back")}
            </Button>
          </div>
        </div>
        {/* {loader == true ? "loading..."

 :  */}
        <div className="body">
          <div className="chat h-full">
            <div className="chat-body lg:p-[30px] pe-4 lg:pe-[30px] max-h-[520px] h-[520px] overflow-y-auto theme-scrollbar">
              <div className="flex items-start gap-lg mb-5 md:flex-row flex-col-reverse">
                <div className="text-content flex-grow">
                  <h6 className="text-base-20 font-500 font-nunito mb-2 ">
                    {ticket?.ticket?.title}
                  </h6>
                  <p className="text-dark-500 text-base font-nunito mb-5">
                    {ticket?.ticket?.message}
                  </p>
                  <h2 className="text-base font-nunito font-500 mb-2 text-gray-dark">{moment(ticket?.ticket?.createdAt).format("DD MMM YY")}</h2>
                  {ticket?.ticket?.file &&
                    <div className="solution-card max-w-[300px] rounded-sm overflow-hidden">
                      {/* <div className="card-header py-2 px-4 bg-red-600">
                        <h6 className="text-xs font-500 text-white text-center">
                          Oops! something went wrong . Please reload the page and
                          true again
                        </h6>
                      </div> */}

                      <div className="card-body">
                        <p className="text-12 font-500  text-center">
                          <div className="img-wrraper w-[260px]  h-[160px] rounded-sm overflow-hidden">
                            <img src={files(ticket?.ticket?.file, 'contact-support')} alt="" className="w-full h-full object-contain object-center" />
                          </div>
                        </p>
                      </div>
                    </div>
                  }
                </div>
                <div className="py-[6px] px-[10px] rounded-[4px] bg-gold text-white max-h-[30px] flex items-center justify-center text-sm font-nunito font-500">
                  {
                    ticket?.ticket?.status == 1 ? 'Pending' : 'Complete'
                  }
                </div>
              </div>

              <ul className="chat-list flex flex-col items-start">
                {
                  replyList?.map((val, index) => (

                    val?.message_by == 2 ?
                      <>

                        {val.message_type === 1 ? (
                          <li className="py-2 px-4 max-w-[80%] bg-light bg-gold text-white inline-block ms-auto text-center text-sm min-w-[120px] rounded-lg mb-2">
                            {val?.message}
                          </li>
                        ) :
                          <>
                            <li className="py-2 px-4 max-w-[80%] text-white inline-block ms-auto"><img
                              src={files(val.message, 'contact-support')}
                              alt=""
                              width={"100px"}
                              height={"100px"}
                              onLoad={() => setLoader(false)} // Set loader to false once the image is loaded
                            /></li>
                          </>
                        }
                      </>
                      :
                      <>
                        {val.message_type == 1 ? (
                          <li className="py-2 px-4 max-w-[80%] bg-theme bg-gray-light inline-block me-auto text-center text-sm min-w-[120px] rounded-lg mb-2">
                            {val?.message}
                          </li>
                        ) :
                          <li className="py-2 px-4 max-w-[80%]  inline-block me-auto text-center text-sm min-w-[120px] rounded-lg mb-2">
                            <img src={files(val.message, 'contact-support')} alt="" width={"100px"} height={"100px"} />
                          </li>
                        }
                      </>
                  ))}
              </ul>

              <div id={'scroll'} ref={scroll} />
            </div>
            <FormikProvider value={formik}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="chat-footer p-0 pt-6 lg:p-[30px]">
                  {ticket?.ticket?.status !== false ?
                    <div className="input-wrapper flex gap-2">
                      <div className="flex flex-grow items-center bg-gray-light  py-1 ps-1 rounded-sm px-4 gap-4 border border-gray-mid">
                        <div className="file-upload flex-shrink-0">
                          <label
                            htmlFor="file-input"
                            className="w-10 h-10 flex items-center justify-center bg-white rounded-sm  cursor-pointer"
                          >
                            <IconUpload2 className="text-black w-5 h-5" />
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleOnChange}
                            id="file-input"
                            className="hidden" />
                        </div>
                        <input
                          placeholder={t('input_filed_placeholder.type_message')}
                          name="message"
                          {...getFieldProps("message")}
                          className="bg-transparent border-none outline-none text-gray-dark !text-sm placeholder:text-sm placeholder:text-gray-dark flex-grow"
                        />
                        {/* <span className="flex-shrink-0  cursor-pointer">
                          <IconEmoji className="text-gray-dark w-5 h-5" />
                        </span> */}
                      </div>
                      <Button
                        className={
                          "p-0 w-[50px] h-[50px] flex items-center justify-center rounded-sm before:hidden group"
                        }
                        disabled={loader}
                      >
                        {loader == true ? <CircularProgress /> : <IconSend className="w-6 h-6 text-white rtl:rotate-180 text-white w-6 h-6 group-hover:ms-2 transition-all duration-75 ease-in-out" />}
                      </Button>
                    </div>
                    : (
                      <div className="text-red-500 text-center">Ticket closed</div>
                    )}
                  {formik.touched.message && formik.errors.message && (
                    <div className="text-red-500">{formik.errors.message}</div>
                  )}
                </div>
              </form>
            </FormikProvider>
          </div>
        </div>

        {/* } */}

      </div>
      <AddTicketModal addTicketModalOpen={addTicketModalOpen} setAddTicketModalOpen={setAddTicketModalOpen} />
    </>
  );
};

export default withTranslation()(TicketDetail);
