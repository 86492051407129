import React from 'react'
import IconSend from '../svg/IconSend'
import { useFormik } from "formik";
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { HOME_API_URL } from '../../api/path';
import * as Yup from "yup";
import Http from '../../api/Http';
import Input from '../../components/ui/Input'
import { withTranslation } from 'react-i18next';

const SubscribeInput = ({t}) => {

    const { enqueueSnackbar } = useSnackbar();

    const subscribeSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email("Must be a valid email address")
            .required(t("validation_message.email_req")),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
        },
        validationSchema: subscribeSchema,
        onSubmit: async (values, { resetForm }) => {
            subscriberRequest(values)
            resetForm()
        },
    });

    const { mutateAsync: subscriberRequest } = useMutation(
        async (data) => {
            await Http.post(
                HOME_API_URL.newsSubscription,
                data
            ).then(({ data }) => {
                enqueueSnackbar(data.message, {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }).catch((error) => {
                const err = error?.response;

                if (err?.data?.message) {
                    enqueueSnackbar(err.data.message, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                } else {
                    enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            });
        }
    );

    const {
        handleSubmit,
        getFieldProps,
        touched,
        errors,
    } = formik;

    return (

        <form onSubmit={handleSubmit} value={formik} autoComplete="off">
            <div className="form-item flex rounded-sm overflow-hidden">
                {/* <input type="text" className='py-3 px-4 outline-none flex-grow text-gray-dark text-sm' placeholder='Enter your email'/> */}
                <Input placeholder={t("front_footer.enter_your_email")}
                    className='py-3 px-4 outline-none flex-grow text-gray-dark text-sm rounded-none rounded-s-sm border-e-0  font-nunito'
                    {...getFieldProps("email")}
                    error={touched.email && errors.email}
                />
                <button className='bg-dark min-w-[56px] flex-shrink-0 flex items-center justify-center overflow-hidden'>
                    <IconSend className="w-6 h-6 text-white rtl:rotate-180" />
                </button>
            </div>
            {errors.email !== undefined ? <span className=' text-sm font-400 text-danger'>{touched.email && errors.email}</span> : ''}
        </form>
    )
}

export default  withTranslation()(SubscribeInput)