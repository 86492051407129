import Product1 from "../assets/images/product-1.png"
import Product2 from "../assets/images/product-2.png"
import Product3 from "../assets/images/product-3.png"
import Product4 from "../assets/images/product-4.png"
import JewelleryMegaMenu from "../components/front/JewelleryMegaMenu"
import MailArtiWorldMegaMenu from "../components/front/MailArtiWorldMegaMenu"


export const HeaderMegaMenuNavigation = [
    { actionLink: "Mailarti World",arText:"عالم ميلارتي", hasSubMenu: true, menuComponent: <MailArtiWorldMegaMenu /> }
]

export const newArrives = [
    {
        title: "Buy Designers Earrings"
    }
]

export const productCategories = [
    "Earrings",
    "Pendants",
    "Rings",
    "Neckwear",
    "Bangles",
    "Bracelets"
]
export const arrivalsSpan = [
    "Poppular",
    "New"
]

export const products = [
    {
        productImg: Product1,
        title: "Eternity Ring In 18K White Gold With Brilliant-Cut Diamonds, 6.59cts"
    },
    {
        productImg: Product2,
        title: "Eternity Ring In 18K White Gold With Brilliant-Cut Diamonds, 6.59cts"
    },
    {
        productImg: Product3,
        title: "Eternity Ring In 18K White Gold With Brilliant-Cut Diamonds, 6.59cts"
    },
    {
        productImg: Product4,
        title: "Eternity Ring In 18K White Gold With Brilliant-Cut Diamonds, 6.59cts"
    },
    {
        productImg: Product1,
        title: "Eternity Ring In 18K White Gold With Brilliant-Cut Diamonds, 6.59cts"
    },
    {
        productImg: Product2,
        title: "Eternity Ring In 18K White Gold With Brilliant-Cut Diamonds, 6.59cts"
    },
    {
        productImg: Product3,
        title: "Eternity Ring In 18K White Gold With Brilliant-Cut Diamonds, 6.59cts"
    },
    {
        productImg: Product4,
        title: "Eternity Ring In 18K White Gold With Brilliant-Cut Diamonds, 6.59cts"
    }
]

export const Categories =[
    {text:"Earrings",id:Math.random()},
    {text:"Rings",id:Math.random()},
    {text:"Bracelates   ",id:Math.random()},
    {text:"Necklaces",id:Math.random()},
    {text:"Anklets",id:Math.random()},
]
export const Stone =[
    {text:"Pink Coral",id:Math.random()},
    {text:"Diamond ",id:Math.random()},
    {text:"Diamond",id:Math.random()}
]
export const Gold = [
    { text: "Yellow", id: Math.random(), value: "yellow", ar_text: "الذهب الأصفر" },
    { text: "White", id: Math.random(), value: "white", ar_text: "ذهب ابيض" },
    { text: "Rose", id: Math.random(), value: 'rose', ar_text: "وارتفع الذهب" },
]
export const productType =[
    {text:"Bracelate",id:Math.random()}
]
export const SortBy =[
    // {text:"RelevancePrice",id:Math.random(),value:""},
    {text:"low to high Price",id:Math.random(),value:1,ar_text:"السعر من الأقل للأعلى"},
    {text:"High To Low Price",id:Math.random(),value:-1,ar_text:"السعر من الأعلى للأقل"}
]

// export const products = ""