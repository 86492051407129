import React, { useState, useMemo } from "react";
import { Button } from "../../components/ui/Button";
// import IconAdd from "../../components/svg/IconAdd";
// import { products } from "../../data/static";
import Http from "../../api/Http";
// import BlankTicket from "../../components/front/BlankTicket";
// import TicketList from "../../components/front/TicketList";
import { ContextState } from "../../context/context";
import IconArrowRight from "../../components/svg/IconArrowRight";
// import AddTicketModal from "../../components/modal/AddTicketModal";

import { USER_API_URL } from '../../api/path'
// import { useQuery } from 'react-query'
import Input from "../../components/ui/Input";
import IconUpload from "../../components/svg/IconUpload";
import { useMutation } from 'react-query';
import { FormikProvider, useFormik } from "formik";
import { ticketSupport } from "../../validation/validation";
import { enqueueSnackbar } from "notistack";
import { PATH_PROFILE } from "../../routes/path";
import { useNavigate } from 'react-router-dom'
import {Helmet  } from "react-helmet-async";
import { withTranslation } from "react-i18next";

const AddTicket = ({t}) => {
  const { isRouteActive, setisRouteActive } = ContextState();
  // const queryClient = useQueryClient();   
  const navigate = useNavigate();

  const [newsFile, setNewsFile] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("No file chosen"); 
  

  //   const ticketKey = useMemo(() => ["ticketApi"]);

  //   const { data: ticketList } = useQuery(
  //     ticketKey,
  //     async () => {
  //       const response = await Http.get(USER_API_URL.ticketList);
  //       return response?.data;
  //     }, {
  //     refetchOnWindowFocus: false,
  //   }); 

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      message: ""
    },
    validationSchema: ticketSupport(t),
    onSubmit: async (values) => {
      const maxFileSizeInBytes = 10 * 1024 * 1024; // 10 MB
      if (newsFile && newsFile.length > maxFileSizeInBytes) {        
        enqueueSnackbar(t("validation_message.file_size_limit"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        return;
      }
  
      values.file = newsFile
      //   setIsLoading(true);
      await ticketCreate(values);
    },
  });


  const { mutateAsync: ticketCreate } = useMutation(
    async (data) => {
      return await Http.post(USER_API_URL.ticketCreate, data);
    },
    {
      onSuccess: async ({ data }) => {
        // queryClient.invalidateQueries(["ticketApi"]);
        
        navigate(PATH_PROFILE.contactSupport)
        resetForm();
        setNewsFile();
        // setSelectedFileName();
        enqueueSnackbar(data.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      },
    }
  );

  const {
    handleSubmit,
    getFieldProps,
    setFieldError,
    touched,
    errors,
    resetForm,
  } = formik;

  const convertFileToBase64 = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const base64 = e.target.result;
      setNewsFile(base64)
    };

    reader.readAsDataURL(file);
  };

  const isImageFile = (fileName) => {
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif']; 
    const ext = fileName.split('.').pop().toLowerCase();
    return allowedExtensions.includes(ext);
  };


//   const handleOnChange = (e) => {
//     const fileList = e.target.files[0];

//     if (fileList) {
//         const fileName = fileList.name;
        
//         if (isImageFile(fileName)) {
//             setSelectedFileName(fileName);
//             convertFileToBase64(fileList);
//         } else {                
//             enqueueSnackbar('Invalid file format. Please choose a valid image file.', {
//                 variant: "error",
//                 anchorOrigin: { vertical: "top", horizontal: "right" },
//                 autoHideDuration: 2000,
//               });
//         }
//     } else {
//         setSelectedFileName("No file chosen");
//     }
// };

  const handleOnChange = (e) => {
    const fileList = e.target.files[0];
    e.target.value = null;

    if (fileList) {
      const fileName = fileList.name;
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];

      const fileExtension = fileName.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension) && isImageFile(fileName)) {
        setSelectedFileName(fileName);
        convertFileToBase64(fileList);
      } else {
        enqueueSnackbar(t("snackbar_message.invalid_file_format"), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
      }
    } else {
      setSelectedFileName('No file chosen');
    }
  };

  return (
    <>
      <Helmet>
        <title>AddTicket | Mailarti </title>
      </Helmet>
      <div className="lg:border border-gray-mid">
        <div className="header lg:bg-gray-light pt-0 pb-[18px] lg:py-[18px] lg:px-[30px] flex items-center gap-4">
          <button
            onClick={() => setisRouteActive(!isRouteActive)}
            className="lg:hidden flex-shrink-0"
          >
            <IconArrowRight className="text-dark w-4 h-4 rotate-180" />
          </button>
          <div className="flex items-center gap-4 flex-grow">
            <h6 className="text-base-18 font-500 text-dark flex-grow">
              {t("button_label.contact_support")}
            </h6>
          </div>
        </div>
        <div className="text-center p-6">
          <FormikProvider value={formik}>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="field-part mb-6">
                <div className="form-item mb-4 text-start">
                  <Input placeholder={t("input_filed_placeholder.ticket_title")}
                    name="title"
                    {...getFieldProps("title")}
                    error={touched.title && errors.title}
                    helpertext={touched.title && errors.title}
                  />

                </div>
                <div className="form-item mb-4 text-start">
                  <Input placeholder={t("input_filed_placeholder.write_message")}
                    name="message"
                    {...getFieldProps("message")}
                    error={touched.message && errors.message}
                    helpertext={touched.message && errors.message}
                  />
                </div>
                <div className="form-item mb-4">
                  <div className='h-[200px] w-full bg-light rounded-sm border border-gray-mid bg-gray-light '>
                    <label htmlFor="dropzone-file" className='flex items-center justify-center flex-col gap-sm h-full' >
                      <div className="icon">
                        <IconUpload className="h-8 w-8" />
                      </div>
                      <h5 className='text-dark text-base font-500'>Upload File</h5>
                      <p className=' text-dark text-sm font-400'>Upload PDF, PNG or JPG format only</p>
                    </label>
                    <input
                      type='file'
                      accept="image/*"
                      onChange={handleOnChange}
                      id='dropzone-file'
                      className='hidden'
                    // ref={ref}
                    />
                    {selectedFileName}
                  </div>
                </div>
              </div>
              <div className="action-part">
                <Button  className={"sm:max-w-[80%] w-full mx-auto"}>
                  {t("button.submit")}
                </Button>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(AddTicket);
