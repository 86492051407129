export default function files(name, type) {
  switch (type) {
    case "latest-news":
      return `${process.env.REACT_APP_IMAGE_URL}/uploads/latestNews/${name}`;
    case "profile":
      return `${process.env.REACT_APP_IMAGE_URL}/uploads/profile/${name}`;
    case "cms":
      return `${process.env.REACT_APP_IMAGE_URL}/uploads/cms/${name}`;
    case "category":
      return `${process.env.REACT_APP_IMAGE_URL}/uploads/category/${name}`    
    case "collection":
      return `${process.env.REACT_APP_IMAGE_URL}/uploads/collection/${name}`
    case "contact-support":
      return `${process.env.REACT_APP_IMAGE_URL}/uploads/contactSupport/${name}`
    case "cover_image":
      return `${process.env.REACT_APP_IMAGE_URL}/uploads/product/${name}`    
    case "thumbnail":
      return `${process.env.REACT_APP_IMAGE_URL}/uploads/product/thumbnail/${name}`
    default:
      return ``;
  }
}