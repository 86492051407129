import './App.css';
import '@smastrom/react-rating/style.css'
import { CategoryProvider } from './contexts/CategoryContext';
import { CollectionProvider } from './contexts/CollectionContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { CmsProvider } from './contexts/cmsContext';
import Router from './routes';
import useStore from "./contexts/AuthProvider";
import Loader from './components/common/Loader';

function App() {
  const { isInitialized } = useStore();

  return (
    <CollectionProvider>
      <CategoryProvider>
        <CmsProvider>
          <LanguageProvider>
            {isInitialized ? <Router /> : <Loader />}
          </LanguageProvider>
        </CmsProvider>
      </CategoryProvider>
    </CollectionProvider>
  );
}

export default App;
