import React from 'react'

const IconC = (props) => {
    return (
        <svg {...props} viewBox="0 0 16 16" fill="none">
            <path d="M13.12 13.12C11.7067 14.5333 10 15.24 8 15.24C6 15.24 4.29333 14.5333 2.88 13.12C1.46667 11.7067 0.76 10 0.76 8C0.76 6 1.46667 4.29333 2.88 2.88C4.29333 1.46667 6 0.76 8 0.76C10 0.76 11.7067 1.46667 13.12 2.88C14.5333 4.29333 15.24 6 15.24 8C15.24 10 14.5333 11.7067 13.12 13.12ZM8 14.64C9.81333 14.64 11.36 13.9933 12.64 12.7C13.9333 11.4067 14.58 9.84 14.58 8C14.58 6.16 13.9333 4.59333 12.64 3.3C11.36 2.00667 9.81333 1.36 8 1.36C6.17333 1.36 4.61333 2.00667 3.32 3.3C2.04 4.59333 1.4 6.16 1.4 8C1.4 9.84 2.04 11.4067 3.32 12.7C4.61333 13.9933 6.17333 14.64 8 14.64ZM8.06 11.86C6.96667 11.86 6.04 11.4867 5.28 10.74C4.52 9.98 4.14 9.06667 4.14 8C4.14 6.93333 4.52 6.02667 5.28 5.28C6.04 4.52 6.96667 4.14 8.06 4.14C8.76667 4.14 9.41333 4.30667 10 4.64C10.5867 4.97333 11.04 5.43333 11.36 6.02L9.98 6.82C9.63333 6.07333 8.98667 5.7 8.04 5.7C7.36 5.7 6.80667 5.92667 6.38 6.38C5.96667 6.83333 5.76 7.37333 5.76 8C5.76 8.62667 5.96667 9.16667 6.38 9.62C6.80667 10.0733 7.36 10.3 8.04 10.3C8.46667 10.3 8.86 10.2 9.22 10C9.58 9.8 9.84 9.52 10 9.16L11.38 9.98C11.06 10.5533 10.6 11.0133 10 11.36C9.41333 11.6933 8.76667 11.86 8.06 11.86Z" fill="currentcolor" />
        </svg>
    )
}

export default IconC