import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import IconDown from "../svg/IconDown";
import { merge } from "../../lib/utils";
import Input from "./Input";

const SelectBox = ({
  prefix,
  buttonClassName = "",
  searchbar = false,
  listItemClassName = "",
  data,
  placeholder = "",
  onChange,
  ...props
}) => {
  const { value,selected, setSelected } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const filteredOptions = searchTerm
    ? data.filter((item) => item.value.toLowerCase().includes(searchTerm))
    : data;

  useEffect(() => {
    if (selected && selected.hasOwnProperty("isPrimary")) {
      props.onIsPrimaryChange && props.onIsPrimaryChange(selected?.isPrimary);
    }
  }, [selected]);

  useEffect(() => {
    if (value && value !== undefined && value !== null) {
        setSelected(data.find((item) => item.key === value));
    }
  }, [value, data]);

  const handleChange = (value) => {
    setSelected(value);
    onChange(value?.key);

    if (value.hasOwnProperty("isPrimary")) {
      props.onIsPrimaryChange && props.onIsPrimaryChange(value?.isPrimary);
    }
  };
  return (
    <div className="">
      <Listbox value={selected?.key} onChange={handleChange}>
        <div className="relative">
          <Listbox.Button
            className={merge(
              "relative w-full text-base font-400 text-gray-dark bg-gray-light border border-gray-mid rounded-sm cursor-default py-[18px] pl-3 pe-10 ps-[52px] text-start focus:outline-none",
              buttonClassName
            )}
          >
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"></span>
            {prefix && (
              <span className="absolute left-[18px] top-[18px]">{prefix}</span>
            )}
            <span className="block truncate">
              {selected ? selected.value : placeholder}
            </span>
            <span className="pointer-events-none absolute inset-y-0 end-2 flex items-center">
              <IconDown className="h-4 w-4 text-dark" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 z-[30] max-h-40 theme-scrollbar w-full overflow-y-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {searchbar && (
                <div className="px-4">
                  <Input
                    className="py-1.5 border-gray-dark focus:border-gray-dark text-sm"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              )}
              {filteredOptions.map((value, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    merge(
                      `relative select-none py-2 pl-10 pr-4 text-start cursor-pointer ${
                        active ? "bg-gray-light text-black" : "text-gray-900"
                      }`,
                      listItemClassName
                    )
                  }
                  value={value}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {value.value}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-black">
                          {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default SelectBox;
