import React from 'react'

const IconX = (props) => {
    return (
        <svg {...props} viewBox="0 0 25 25" fill="none">
            <path d="M8.21008 2.00586H1.18848L9.47483 13.0544L1.63982 22.0675H4.29802L10.7061 14.6959L16.2348 22.0676H23.2564L14.6215 10.5545L22.0528 2.00586H19.3946L13.3903 8.91289L8.21008 2.00586ZM17.2379 20.0614L5.20082 4.01203H7.20699L19.244 20.0614H17.2379Z" fill="currentcolor" />
        </svg>
    )
}

export default IconX