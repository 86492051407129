import React, { useMemo, useState } from "react";
import IconArrowRight from "../../components/svg/IconArrowRight";
import { ContextState } from "../../context/context";
import { Button } from "../../components/ui/Button";
import ShippingAddressRadioGroup from "../../components/ui/ShippingAddressRadioGroup";
import AddAddressModal from "../../components/modal/AddAddressModal";
import { useQuery } from "react-query";
import Http from "../../api/Http";
import { USER_API_URL } from "../../api/path";
import {Helmet  } from "react-helmet-async";
import { withTranslation } from "react-i18next";
const AddressDetail = ({t}) => {

  const { isRouteActive, setisRouteActive } = ContextState();
  const [addAddressModalOpen, setAddAddressModalOpen] = useState(false)
  const [addressData, setAddressData] = useState([]);
  const [editData, setEditData] = useState("");

  const addresses = useMemo(() => ["addressApi"]);

  const { data: addressList,refetch, isLoading } = useQuery(
    addresses,
    async () => {
      const response = await Http.get(USER_API_URL.addressList);
      setAddressData(response?.data)
      return response?.data;
    }, {
    refetchOnWindowFocus: false,
  });

  const handleClickOpen = () => {
    setEditData("")
    setAddAddressModalOpen(true);
  };

  const handleClose = () => {
    setAddAddressModalOpen(false)
  }

  return (
    <>
    <Helmet>
        <title>AddressDetails | Mailarti </title>
      </Helmet>
      <div className="lg:border border-gray-mid h-full">
        <div className="header lg:bg-gray-light pt-0 pb-[18px] lg:py-[18px] lg:px-[30px] flex items-center gap-4">
          <button
            onClick={() => setisRouteActive(!isRouteActive)}
            className="lg:hidden flex-shrink-0 rtl:rotate-180"
          >
            <IconArrowRight className="text-dark w-4 h-4 rotate-180" />
          </button>
          <div className="flex  items-center gap-4 justify-between flex-grow">
            <h6 className="text-base-18 font-500 text-dark" >{t('button_label.address')}</h6>
            <Button size={"sm"} onClick={() => handleClickOpen()}>+ {t('button_label.new_address')}</Button>
          </div>
        </div>
        <div className="body lg:p-[30px]">
          <ShippingAddressRadioGroup refetch={refetch} addressList={addressData} setAddAddressModalOpen={setAddAddressModalOpen} setEditData={setEditData} />
        </div>
      </div>
      <AddAddressModal addAddressModalOpen={addAddressModalOpen} setAddAddressModalOpen={setAddAddressModalOpen} handleClose={handleClose} editData={editData} refetch={refetch} />
    </>
  );
};

export default withTranslation()(AddressDetail);
