import React, { useMemo } from 'react'
import Logo from "../../components/svg/Logo";
import { Link } from 'react-router-dom';
import IconC from '../../components/svg/IconC';
import IconFacebook from '../../components/svg/IconFacebook';
import IconInstagram from '../../components/svg/IconInstagram';
import IconX from '../../components/svg/IconX';
import SubscribeInput from '../../components/common/SubscribeInput';
import { HOME_API_URL } from '../../api/path';
import { useQuery } from 'react-query';
import Http from '../../api/Http';
import useCollection from '../../hooks/useCollection';
import { withTranslation } from 'react-i18next';
import useLanguage from '../../hooks/useLanguage';

const FrontFooter = ({ t }) => {
  const queryKey = useMemo(() => ["commonSettingListApi"]);
  const { data: commonSettingList } = useQuery(
    queryKey,
    async () => {
      const response = await Http.get(HOME_API_URL.commonSettingList);
      return response?.data;
    }, {
    refetchOnWindowFocus: false,
  });

  const { collectionList } = useCollection()

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const { language } = useLanguage()

  return (
    <footer className='front-footer bg-white pt-14 mt-[32px] lg:mt-[80px] md:mt-[60px] border border-gray-mid'>
      <div className="footer-wrapper">
        <div className="footer-sub1 pb-8 border-b border-gray-mid">
          <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
              <div className="logo-part md:col-span-6 xl:col-span-3">
                <div className="logo mb-8">
                  <Logo className="text-dark w-[194.64px] h-[32px] max-w-[140px] lg:max-w-full" />
                </div>
                <p className='text-dark max-w-[90%] font-nunito'>
                  {language == 'ar' ? commonSettingList?.ar_footerText : commonSettingList?.footerText ?? ""}
                </p>
              </div>
              <div className="md:col-span-12 xl:col-span-6 grid grid-cols-1 xsm:grid-cols-3 gap-4 order-3 xl:order-2">
                <div className="menu-part">
                  {collectionList.length > 0 ? <div className="menu-part">
                    <div className="">
                      <h6 className='text-base-20 text-dark font-600 mb-3 rtl:font-500 uppercase !font-cormorant'>{t('front_footer.collection')}</h6>
                      <ul>
                        {
                          collectionList?.map((value, key) => (
                            <li className='mb-3' key={key}>
                              <Link to={"category-products"} onClick={scrollTop} state={{ collectionId: value?._id, collectionName: value?.name, ar_collectionName: value?.ar_name }} className='text-sm font-500 font-nunito text-dark transition duration-300  hover:text-black'>{language == 'ar' ? value?.ar_name : value.name}</Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div> : ''}
                </div>
                <div className="menu-part">
                  <div className="">
                    <h6 className='text-base-20 text-dark font-600 mb-3 rtl:font-500 uppercase !font-cormorant'>{t('front_footer.pages')}</h6>
                    <ul>
                      <li className='mb-3'>
                        <Link to={"/"} onClick={scrollTop} className='text-sm font-500 font-nunito text-dark transition duration-300  hover:text-black'>{t('front_footer.home')}</Link>
                      </li>
                      <li className='mb-3'>
                        <Link to={"/terms-and-condition"} onClick={scrollTop} className='text-sm font-500 font-nunito text-dark transition duration-300  hover:text-black'>{t('front_footer.terms_condition')}</Link>
                      </li>
                      <li className='mb-3'>
                        <Link to={"/privacy-policy"} onClick={scrollTop} className='text-sm font-500 font-nunito text-dark transition duration-300  hover:text-black'>{t('front_footer.privacy_policy')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="menu-part">
                  <h6 className='text-base-20 text-dark font-600 mb-3 rtl:font-500 uppercase !font-cormorant'>{t('front_footer.need_help')}</h6>
                  <ul>
                    <li className='mb-3'>
                      <Link to={"/contact-us"} onClick={scrollTop} className='text-sm font-500 font-nunito text-dark transition duration-300  hover:text-black'>{t('front_header.contact')}</Link>
                    </li>
                    <li className='mb-3'>
                      <Link to={""} onClick={scrollTop} className='text-sm font-500 font-nunito text-dark transition duration-300  hover:text-black'>{t('front_footer.service')}</Link>
                    </li>
                    <li className='mb-3'>
                      <Link to={""} onClick={scrollTop} className='text-sm font-500 font-nunito text-dark transition duration-300  hover:text-black'>{t('front_footer.returns')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="menu-part md:col-span-6 xl:col-span-3 order-2 xl:order-3">
                <h6 className='text-base-20 text-dark font-600 mb-3 rtl:font-500 uppercase !font-cormorant'>{t('front_footer.newsletter')}</h6>
                <ul>
                  <li className='mb-3'>
                    <div className="form-item">
                      <label htmlFor="" className='block  font-nunito text-sm font-400 text-dark mb-3.5'>
                        {t('front_footer.footer_text')}
                      </label>
                      <SubscribeInput />
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center gap-sm">
                      <h6 className='!text-sm text-gray-new/50 font-600 uppercase font-nunito'>
                        {t('front_footer.social_media')}
                      </h6>
                      <div className="social-icon flex items-center gap-sm">
                        <Link to={commonSettingList?.facebook ?? "/"} target="_blank">
                          <IconFacebook className="w-6 h-6 text-black" />
                        </Link>
                        <Link to={commonSettingList?.instagram ?? "/"} target="_blank">
                          <IconInstagram className="w-6 h-6 text-black transition duration-300" />
                        </Link>
                        <Link to={commonSettingList?.tiktok ?? "/"} target="_blank">
                          <IconX className="w-6 h-6 text-black transition duration-300" />
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-sub2 pt-4 md:pt-6">
            <h6 className='!text-base flex items-center justify-center gap-sm  text-gray-new/50  font-600'>
              <span className=''>
                <IconC className="w-5 h-5 text-gray-dark" />
              </span>
              {t('front_footer.footer_text1')}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default withTranslation()(FrontFooter)