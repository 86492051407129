import React from 'react'

const IconMenu = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.96875 9.16699H34.777M4.96875 20.0003H34.777M4.96875 30.8337H34.777" stroke="currentcolor" strokeWidth="1.4" strokeLinecap="round" />
        </svg>
    )
}

export default IconMenu