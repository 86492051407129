import React from 'react'
import Lottie from "lottie-react";
import Loder from "./loder.json";
import LoaderGif from "../../assets/images/Loader.gif";

const Loader = () => {
  return (
    <div className='w-screen h-screen fixed top-0 left-0 bg-light dark:bg-dark-950/20 flex items-center justify-center z-[999] bg-white'>
      <img src={LoaderGif} alt="" />
    </div>
  )
}

export default Loader